import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, List } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { ModalLoading } from "../../components/modal";
import ListEntrega from './entrega_List'


export default props => {
    const theme = useTheme();
    const [modalAtive, setModalAtive] = useState(false)
    function closeModal() {
        setModalAtive(false)
    }
    return (
        <Box id={'init'} sx={{ height: '100%', backgroundColor: theme.palette.background.default, width: '100%' }}>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
                    <LocalShippingIcon sx={{ fontSize: 80, alignSelf: 'center', color: theme.palette.secondary.main }} />
                    <Typography variant="h5" textAlign={'center'} fontWeight={'bold'}>Escolha uma opção de entrega</Typography>
                </Box>
                <Box height={30} width={'100%'} bgcolor={theme.palette.background.paper} />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', paddingTop: 5, paddingBottom: 5, rowGap: 2 }}>
                    <Box sx={{
                        width: '100%',
                        [theme.breakpoints.down('md')]: {
                            width: '100%'
                        },
                    }}>
                        <List >
                            <ListEntrega form={props.form} updateForm={props.updateForm} />
                        </List>
                    </Box>
                    <Box sx={{ display: 'flex', alignSelf: 'center', gap: 1, marginRight: 5 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mb: 2, maxWidth: 300, fontWeight: 'bold', backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                            onClick={() => {
                                props.changeOpen(2)
                            }}
                        ><Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Voltar</Typography></Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mb: 2, maxWidth: 300, fontWeight: 'bold', backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                            onClick={async () => {
                                if (props.form.entrega.id === null) {
                                    return
                                }
                                props.changeOpen(1)
                            }}
                        ><Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Próximo</Typography></Button>
                    </Box>
                </Box>
            </Box >
            <ModalLoading ative={modalAtive} closeModal={closeModal} />
        </Box >
    )
}