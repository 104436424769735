import React, { useContext, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Collapse, ListItemIcon, ListItemButton, ListItemText, Typography, Button } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ModalLoading } from "../../components/modal";
import { SnackBarAlert, SnackBarSucess } from "../../components/snackbar";
import ListProducts from "../../components/listProducts";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Product } from "../../contexts/product";


export default props => {
    const theme = useTheme();
    const product = useContext(Product)
    const [modalAtive, setModalAtive] = useState(false)
    const [snackBar, setSnackBar] = useState({
        id: 1,
        open: false,
        message: ""
    })

    function closeModal() {
        setModalAtive(false)
    }
    function closeSnack() {
        const x = { ...snackBar }
        x.open = false
        setSnackBar(x)
    }

    return (
        <Box sx={{ height: '100%', backgroundColor: theme.palette.background.default, width: '100%' }}>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
                    <ShoppingCartIcon sx={{ fontSize: 80, alignSelf: 'center', color: theme.palette.secondary.main }} />
                    <Typography variant="h5" textAlign={'center'} fontWeight={'bold'}>Escolha uma opção de produto</Typography>
                </Box>
                <Box height={30} width={'100%'} bgcolor={theme.palette.background.paper} />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', padding: 5, rowGap: 2 }}>
                    <Box sx={{
                        width: '100%'
                    }}>
                        <ListProducts form={props.form} open={props?.open?.produto?.open} updateForm={props.updateForm} />
                    </Box>
                    <Box sx={{ display: 'flex', alignSelf: 'center', gap: 1 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mb: 2, maxWidth: 300, fontWeight: 'bold', backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                            onClick={() => {
                                console.log(product.data.activeItem)
                                if (product.data.activeItem && product.data.activeItem.length === 0) {
                                    const x = { ...snackBar }
                                    x.open = true
                                    x.message = 'Selecione ao menos uma opção de produto'
                                    props.setSnackBar(x)
                                    return
                                }
                                props.changeOpen(1)
                            }}
                        ><Typography sx={{ color: '#FFF', fontWeight: 'bold' }}>Próximo</Typography></Button>
                    </Box>
                </Box>
            </Box >
            <ModalLoading ative={modalAtive} closeModal={closeModal} />
            <SnackBarAlert ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
            <SnackBarSucess ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
        </Box >
    )
}