import ReactPixel from 'react-facebook-pixel';

export const Pixel = () => {
    console.log('pageView')
    ReactPixel.pageView()
}

export const PixelInit = () => {
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init('302218922531849', null, options);

}