import React, { useState, useContext, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Collapse, ListItemIcon, ListItemButton, TextField, Typography, Button, Divider, IconButton } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import mainApi from "../../apis/mainApi";
import { ModalLoading } from "../../components/modal";
import { SnackBarAlert, SnackBarSucess } from "../../components/snackbar";
import { Product } from "../../contexts/product";
import { User } from "../../contexts/user";
import ReceiptIcon from '@mui/icons-material/Receipt';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { ModalCupom } from "../../components/modalCupom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ModalRegistro } from "../../components/modalCadastro";

export default props => {
    const theme = useTheme();
    const [modalAtive, setModalAtive] = useState(false)
    const [snackBar, setSnackBar] = useState({
        id: 1,
        open: false,
        message: ""
    })
    const [cupom, setCupom] = useState({
        ative: false,
        id: '',
        codigo: '',
        parceiro_id: '',
        parceiro_nome: '',
        valor: '',
        tipo_id: '',
        tipo_nome: '',
        validade: '',
        quantidade: '',
        usados: '',
        usar: false
    })
    const [registroModal, setRegistroModal] = useState(false)
    async function setVenda(newUser) {
        const body = {
            id: product.data.id,
            title: product.data.name,
            description: product.data.description,
            quantity: product.data.activeItem.quantidade,
            price: getAmount(),
            unit_price: parseFloat(product.data.activeItem.preco),
            cupom: cupom,
            payer: {
                address: {
                    zip_code: props.form.cep,
                    street_name: props.form.rua,
                    street_number: parseInt(props.form.numero)
                },
                email: newUser ? newUser.email : user.data?.email,
                identification: {
                    type: 'CPF',
                    number: newUser ? newUser.cpf : user?.data?.cpf
                }
            }
        }
        function Fcallbak(callback) {
            if (callback?.status !== 200) {
                const x = { ...snackBar }
                x.open = true
                x.id = 1
                x.message = callback?.apiReturn?.message ? callback?.apiReturn?.message : 'Não foi possível realizar o pagamento.'
                props.setSnackBar(x)
                props.modalLoading()
                return
            }
            const newPreferences = { ...props.form }
            newPreferences.pagamento.inicialization = {
                preferenceId: callback?.apiReturn?.apiReturn?.id,
                amount: callback?.apiReturn?.apiReturn?.amount,
                payer: {
                    firstName: newUser ? newUser.nome : user?.data?.nome,
                    lastName: newUser ? newUser.sobrenome : user?.data?.sobrenome,
                    email: newUser ? newUser.email : user?.data?.email,
                    identification: {
                        type: 'CPF',
                        number: newUser ? newUser.cpf : user?.data?.cpf
                    },
                    address: {
                        zipCode: props?.form?.cep,
                        federalUnit: props?.form?.estado,
                        city: props?.form?.cidade,
                        neighborhood: props?.form?.bairro,
                        streetName: props?.form?.rua,
                        streetNumber: (props?.form?.numero).toString()
                    }

                },
            }
            newPreferences.pagamento.customization = callback?.apiReturn?.apiReturn?.customization
            newPreferences.pagamento.open = true
            newPreferences.cupom = {
                id: cupom.id,
                parceiro_id: cupom.parceiro_id
            }
            props.updateForm(newPreferences)
        }
        await mainApi('mercadoppreferences', 'POST', body, Fcallbak)
    }
    function closeModal() {
        setModalAtive(false)
    }
    function closeSnack() {
        const x = { ...snackBar }
        x.open = false
        setSnackBar(x)
    }

    function formatModalCupom(value, value2) {
        const newX = { ...cupom }
        if (value === 'see') {
            newX.ative = true
            setCupom(newX)
            return
        }
        if (!value) {
            newX.ative = false
            newX.usar = false
            setCupom(newX)
            return
        }
        newX.id = value.id
        newX.parceiro_id = value.parceiro_id
        newX.parceiro_nome = value.parceiro_nome
        newX.quantidade = value.quantidade
        newX.tipo_id = value.tipo_id
        newX.tipo_nome = value.tipo_nome
        newX.usados = value.usados
        newX.validade = value.validade
        newX.valor = value.valor
        newX.ative = value2 ? false : true
        newX.usar = value2 ? true : false
        setCupom(newX)
    }
    function getAmount() {
        const valorComFrente = props?.form?.entrega?.id ? (parseFloat(product?.data?.activeItem?.preco) + parseFloat(props?.form.entrega.preco)) : parseFloat(product?.data?.activeItem?.preco)
        if (!cupom.usar) {
            return valorComFrente.toFixed(2)
        }
        if (cupom.tipo_id === 1) {
            return (parseFloat(product?.data?.activeItem?.preco) + parseFloat(cupom.valor)).toFixed(2)
        }
        if (cupom.tipo_id === 2) {
            return (valorComFrente * (1 - (cupom.valor / 100))).toFixed(2)
        }
        if (cupom.tipo_id === 3) {
            return (valorComFrente - cupom.valor).toFixed(2)
        }
    }
    async function validaCupom() {
        function FCallback(callback) {
            if (callback.status !== 200) {
                const x = { ...snackBar }
                x.open = true
                x.id = 1
                x.message = callback?.apiReturn?.message ? callback?.apiReturn?.message : 'Não foi possível validar o cupom.'
                props.setSnackBar(x)
                return
            }
            formatModalCupom(callback.apiReturn.apiReturn)
        }

        const body = {
            token: user.data.token,
            codigo: cupom.codigo
        }
        await mainApi('cupom/valida_cupom', 'POST', body, FCallback)
    }
    const product = useContext(Product)
    const user = useContext(User)
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default, width: '100%' }}>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
                    <ReceiptIcon sx={{ fontSize: 80, alignSelf: 'center', color: theme.palette.secondary.main }} />
                    <Typography variant="h5" textAlign={'center'} fontWeight={'bold'}>Resumo do Pedido</Typography>
                </Box>
                <Box height={30} width={'100%'} bgcolor={theme.palette.background.paper} />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', rowGap: 2 }}>
                    <Box width={'100%'} minHeight={500} bgcolor={theme.palette.background.default} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                            flexDirection: 'column'
                        },
                    }}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ border: 1, borderBottom: 0, borderColor: theme.palette.background.paper, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 2, gap: 2 }}>
                                <Box display={'flex'} gap={1}>
                                    <Typography variant="h3" fontWeight={'bold'}>Informações de entrega</Typography>
                                    <Button sx={{ border: 1, borderColor: theme.palette.secondary.main }} onClick={() => props.changeOpen(null, 1)}>
                                        <EditIcon sx={{ color: theme.palette.secondary.main }} />
                                    </Button>
                                </Box>
                                <Box display={'flex'} flexDirection={'column'} gap={2}>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography variant="h5" fontWeight={'bold'}>Rua:</Typography>
                                        <Typography>{props?.form?.rua}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography variant="h5" fontWeight={'bold'}>Número:</Typography>
                                        <Typography>{props?.form?.numero}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography variant="h5" fontWeight={'bold'}>Bairro:</Typography>
                                        <Typography>{props?.form?.bairro}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography variant="h5" fontWeight={'bold'}>Cidade:</Typography>
                                        <Typography>{props?.form?.cidade}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography variant="h5" fontWeight={'bold'}>Estado:</Typography>
                                        <Typography>{props?.form?.estado}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography variant="h5" fontWeight={'bold'}>CEP:</Typography>
                                        <Typography>{props?.form?.cep}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography variant="h5" fontWeight={'bold'}>Complemento:</Typography>
                                        <Typography>{props?.form?.complemento}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', border: 1, gap: 2, borderColor: theme.palette.background.paper, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Typography variant="h5" fontWeight={'bold'}>Cupom</Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <TextField
                                        margin="none"
                                        value={cupom.codigo}
                                        size='small'
                                        id="cupom"
                                        name="cupom"
                                        label="Insirá seu cupom"
                                        onChange={(value) => {
                                            const x = { ...cupom }
                                            x.codigo = value.target.value
                                            setCupom(x)
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.text.secondary,
                                                borderColor: '#eee'
                                            }
                                        }}
                                        sx={{ maxWidth: '40%' }}
                                        autoComplete=""
                                        inputProps={{
                                            style: {
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: 5
                                            }
                                        }}
                                    />
                                    <IconButton
                                        type="submit"
                                        variant="contained"
                                        sx={{ fontWeight: 'bold', backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0 }}
                                        onClick={validaCupom}
                                    ><SearchIcon /></IconButton>
                                    {cupom.usar ?
                                        <IconButton
                                            type="submit"
                                            variant="contained"
                                            sx={{ fontWeight: 'bold', backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0 }}
                                            onClick={() => formatModalCupom('see')}
                                        ><VisibilityIcon /></IconButton>
                                        : null}
                                </Box>
                            </Box>
                            <Typography variant="h3" fontWeight={'bold'}>Resumo do Pedido</Typography>
                            <Divider sx={{ backgroundColor: theme.palette.text.primary }} />
                            <Box sx={{
                                display: 'flex', gap: 6, position: 'relative', [theme.breakpoints.down('md')]: {
                                    flexDirection: 'column',
                                    gap: 1
                                },
                            }}>
                                <Box>
                                    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', gap: 3 }}>
                                            <Typography variant="h4" fontWeight={'bold'}>Produto</Typography>
                                            <Button sx={{ border: 1, borderColor: theme.palette.secondary.main }} onClick={() => props.changeOpen(null, 0)}>
                                                <EditIcon sx={{ color: theme.palette.secondary.main }} />
                                            </Button>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <Typography variant="h5" fontWeight={'bold'}>Nome: </Typography>
                                            <Typography>{product.data.activeItem.quantidade === 1 ? 'VitLov ' + product.data.activeItem.quantidade + ' unidade' : 'VitLov ' + product.data.activeItem.quantidade + ' unidades'}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                        <Typography variant="h5" fontWeight={'bold'}>Preço: </Typography>
                                        <Typography>{'R$' + product.data.activeItem.preco}</Typography>
                                    </Box>
                                    <Box display={'flex'} flexDirection={'column'} gap={2} mt={2}>
                                        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                                            <Typography variant="h4" fontWeight={'bold'}>Frete</Typography>
                                            <Button sx={{ border: 1, borderColor: theme.palette.secondary.main }} onClick={() => props.changeOpen(null, 2)}>
                                                <EditIcon sx={{ color: theme.palette.secondary.main }} />
                                            </Button>
                                        </Box>
                                        <Box display={'flex'} gap={1} alignItems={'center'}>
                                            <Typography variant="h5" fontWeight={'bold'}>Tipo: </Typography>
                                            <Typography variant="h5" >{props?.form?.entrega?.name ? props?.form?.entrega?.name : ''}</Typography>
                                        </Box>
                                        <Box display={'flex'} gap={1} alignItems={'center'}>
                                            <Typography variant="h5" fontWeight={'bold'}>Preço: </Typography>
                                            <Typography>{props?.form?.entrega?.id ? 'R$' + parseFloat(props?.form?.entrega?.preco).toFixed(2) : 'R$0.00'}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <img src={product?.data?.activeItem?.image} style={{ objectFit: 'contain', width: 200, position: 'absolute', top: 0, left: '50%' }} />
                            </Box>
                            <Divider sx={{ backgroundColor: theme.palette.text.primary }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Typography variant="h5" fontWeight={'bold'}>Total do Pedido</Typography>
                                <Typography variant="h5" fontWeight={'bold'} sx={{ fontSize: 30 }}>{'R$' + getAmount()}</Typography>
                                <Box sx={{
                                    display: 'flex', gap: 3, alignItems: 'center', [theme.breakpoints.down('md')]: {
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        width: '90%',
                                        alignItems: 'center'
                                    },
                                }}>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        sx={{ maxWidth: 300, fontWeight: 'bold', backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                                        onClick={async () => {
                                            if (user?.data?.token) {
                                                props.modalLoading(true)
                                                setVenda()
                                            }
                                            else {
                                                setRegistroModal(true)
                                            }
                                        }
                                        }
                                    ><Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Pagar</Typography></Button>
                                    <Box sx={{ backgroundColor: '#1E1E1E', padding: 1, borderRadius: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src="../assets/images/resumo/compra_protegida.png" style={{ objectFit: 'contain', width: 200 }} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
            <ModalCupom data={cupom} ative={cupom.ative} closeModal={formatModalCupom} />
            <ModalLoading ative={modalAtive} closeModal={closeModal} />
            <SnackBarAlert ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
            <SnackBarSucess ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
            <ModalRegistro ative={registroModal} closeModal={() => setRegistroModal(false)} getCompra={setVenda}></ModalRegistro>
        </Box >
    )
}