import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, List } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { ModalLoading } from "../../components/modal";
import LeftBar from "./left-bar";
import mainApi from "../../apis/mainApi";
import MeusDados from "./meusDados";
import Pedidos from "./pedidos";
import StatusScreen from "../../components/statusScreen";
import { ModalInfo } from "../../components/modalInfo";
import { useLocation } from "react-router-dom";



export default props => {
    const theme = useTheme();
    const [modalAtive, setModalAtive] = useState(true)
    function closeModal() {
        setModalAtive(false)
    }
    const modalInfoInitial = {
        open: false,
        body: (
            <Box>
                <Typography>teste</Typography>
            </Box>)
    }
    const [modalInfo, setModalInfo] = useState(modalInfoInitial)
    const [statusScreen, setStatusScreen] = useState({
        active: false,
        id: null,
        paymentMethod: null,
        serverId: null,
        dateCreated: null
    })
    const useQuery = () => new URLSearchParams(useLocation().search);
    const [leftOpen, setLeftOpen] = useState(false)
    const [form, setForm] = useState({
        cpf: '',
        primeiro_nome: '',
        sobrenome: '',
        email: '',
        senha: '',
        ddd: '',
        celular: '',
        cep: '',
        rua: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        complemento: '',
        salvarEndereco: false,
        entrega: {
            id: null,
            name: '',
            preco: null,
            prazo: null,
            load: false
        },
        pagamento: {
            inicialization: null,
            customization: null,
            open: false
        }
    })
    const [activeScreen, setActiveScreen] = useState(0)
    const [screens, setScreens] = useState([])
    function updateScreen(idx) {
        setActiveScreen(idx)
    }
    function changeLeftOpen(value) {
        setLeftOpen(value)
    }
    async function getData() {
        function FCallback(callback) {
            setModalAtive(false)
            if (callback.status === 200) {
                if (Array.isArray(callback?.apiReturn?.apiReturn)) {
                    setScreens(callback.apiReturn.apiReturn)
                }
            }
        }
        await mainApi('minha_conta_modulos', 'GET', null, FCallback)
    }
    function updateStatusScreen(statusScreen, form) {
        setForm(form)
        setStatusScreen(statusScreen)
    }
    function updateModal(value) {
        setModalAtive(value)
    }
    function closeModalInfo() {
        setModalInfo(modalInfoInitial)
    }
    function updateModalInfo(value) {
        setModalInfo(value)
    }
    function desactiveStatusScreen() {
        const x = { ...statusScreen }
        x.active = false
        window.statusScreenBrickController.unmount()
        setStatusScreen(x)
    }
    const query = useQuery();
    var x = query.getAll('t')
    useEffect(() => {
        if (![undefined, null, ''].includes(x[0])) {
            setActiveScreen(parseInt(x[0]))
        }
        getData()
    }, [window.location.href])
    return (
        <Box sx={{
            height: '110%', backgroundColor: theme.palette.background.paper, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingTop: 5,
            [theme.breakpoints.down('md')]: {
                height: 'auto',
            },
        }}>
            <Box sx={{
                width: '70%',
                backgroundColor: theme.palette.background.default,
                minHeight: '85%',
                display: 'flex',

                [theme.breakpoints.down('md')]: {
                    width: '95%'
                },
            }}>
                <LeftBar updateScreen={updateScreen} activeScreen={activeScreen} screens={screens} changeLeftOpen={changeLeftOpen} open={leftOpen} />
                <Box sx={{
                    width: '100%', height: '100%',
                    [theme.breakpoints.down('md')]: {
                        display: leftOpen === true ? 'none' : 'block'
                    },
                }}>
                    {activeScreen === 0 ?
                        <MeusDados updateModal={updateModal} />
                        : activeScreen === 1 ?
                            <Pedidos updateModal={updateModal} updateStatusScreen={updateStatusScreen} form={form} statusScreen={statusScreen} updateModalInfo={updateModalInfo} closeModalInfo={closeModalInfo} />
                            : null}
                </Box>
            </Box>
            <ModalLoading ative={modalAtive} closeModal={closeModal} />
            <ModalInfo ative={modalInfo.open} closeModal={closeModalInfo} body={modalInfo.body} />
            <StatusScreen canDesactive={true} form={form} active={statusScreen.active} id={statusScreen.id} paymentMethod={statusScreen.paymentMethod} desactive={desactiveStatusScreen} serverId={statusScreen.serverId} dateCreated={statusScreen.dateCreated} />
        </Box >
    )
}