import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Collapse } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { User } from '../../contexts/user'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import moment from 'moment'
import 'moment/locale/pt-br'
import { Link } from "react-router-dom";

export default props => {
    moment.locale('pt-br');
    const theme = useTheme();
    const user = useContext(User)
    const [data, setData] = useState([])
    const [activeCollapse, setActiveCollapse] = useState(null)

    async function getData() {
        props.updateModal(true)
        function FCallback(callback) {
            props.updateModal(false)
            if (callback.status === 200) {
                if (Array.isArray(callback.apiReturn.apiReturn)) {
                    setData(callback.apiReturn.apiReturn)
                }
            }
        }
        await mainApi('pedidos/cliente', 'POST', { token: user?.data?.token }, FCallback)
    }
    useEffect(() => {
        getData()
    }, [user])
    return (
        <Box sx={{
            width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 5,
            [theme.breakpoints.down('md')]: {
                padding: 1
            },
        }}>
            <Box sx={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.palette.background.paper, width: '100%', padding: 2 }}>
                <ShoppingCartIcon sx={{ fontSize: 70, color: theme.palette.primary.main, }} />
                <Typography fontWeight={'bold'} sx={{ fontSize: 20 }}>Pedidos</Typography>
            </Box>
            <Box className="list-pedidos" width={'100%'} sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 3, maxHeight: 600, overflow: 'scroll', overflowX: 'unset', '&::-webkit-scrollbar': {
                    background: theme.palette.background.paper,
                    paddingRight: 1
                },
                '&::-webkit-scrollbar-thumb': {
                    background: theme.palette.primary.main
                },
                '&::-webkit-scrollbar-button': {
                    display: 'none'
                },
            }}>
                {data.length > 0 ? data.map((e, idx) => {
                    return (
                        <Box key={idx} sx={{ backgroundColor: theme.palette.background.paper, width: '98%', borderRadius: 1 }}>
                            <Box sx={{ borderBottom: 1, padding: 3, borderColor: theme.palette.background.default, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography fontWeight={'bold'}>{moment(e?.data_pedido).format('LL')}</Typography>
                                <Button
                                    sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                                    onClick={() => {
                                        if (activeCollapse === idx) {
                                            setActiveCollapse(null)
                                            return
                                        }
                                        setActiveCollapse(idx)
                                    }}
                                >
                                    <Typography fontWeight={'bold'}>Detalhes</Typography>

                                </Button>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
                                <img src={e.image} width={100} style={{ objectFit: 'contain' }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Box>
                                        <Typography fontWeight={'bold'} sx={{ color: e?.status === 4 ? theme.palette.error.main : e?.status === 0 ? theme.palette.secondary.main : theme.palette.primary.main }}>{e?.info}</Typography>
                                        <Typography>{e?.description}</Typography>
                                    </Box>
                                    <Typography fontWeight={'bold'}>{e?.nome}</Typography>
                                </Box>
                            </Box>
                            <Collapse in={activeCollapse !== null && activeCollapse === idx} timeout="auto" unmountOnExit>
                                <Box sx={{
                                    borderTop: 1, borderColor: theme.palette.background.default, padding: 3, display: 'flex', gap: 10,
                                    [theme.breakpoints.down('md')]: {
                                        flexDirection: 'column',
                                        gap: 3
                                    },
                                }}>
                                    <Box>
                                        <Typography fontWeight={'bold'} fontSize={20}>INFORMAÇÕES</Typography>
                                        <Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>id: </Typography>
                                                <Typography>{e?.id}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>Produto Preço: </Typography>
                                                <Typography>{'R$' + parseFloat(e?.produto_preco).toFixed(2)}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>Forma de pagamento: </Typography>
                                                <Typography>{e?.pagamento_name}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>Frete: </Typography>
                                                <Typography>{e?.frete_name}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>Custo do Frete: </Typography>
                                                <Typography>{'R$' + parseFloat(e?.entrega_custo).toFixed(2)}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>Total: </Typography>
                                                <Typography>{'R$' + parseFloat(e?.amount).toFixed(2)}</Typography>
                                            </Box>
                                        </Box>
                                        {[1, 2].includes(e?.id_pagamento) && e?.status === 0 ?
                                            <Button
                                                sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                                                onClick={() => {
                                                    const newForm = { ...props.form }
                                                    newForm.email = e?.email
                                                    newForm.cpf = e?.cpf
                                                    newForm.primeiro_nome = e?.nome_cliente
                                                    newForm.sobrenome = e?.sobrenome
                                                    newForm.cep = e?.cep
                                                    newForm.rua = e?.rua
                                                    newForm.numero = e?.numero
                                                    newForm.bairro = e?.bairro
                                                    newForm.cidade = e?.cidade
                                                    newForm.estado = e?.estado
                                                    newForm.complemento = e?.complemento
                                                    newForm.entrega = {
                                                        id: e?.entrega_id,
                                                        name: e?.frete_name,
                                                        preco: e?.entrega_custo,
                                                        prazo: {
                                                            type: 1,
                                                            value: e?.entrega_prazo
                                                        }
                                                    }
                                                    newForm.product = {
                                                        quantidade: 1,
                                                        preco: e?.produto_preco,
                                                        image: e?.image,
                                                    }
                                                    const newStatusScreen = {
                                                        active: true,
                                                        id: e?.order_id,
                                                        paymentMethod: e?.pagamento_name,
                                                        serverId: e?.id,
                                                        dateCreated: e?.data_pedido
                                                    }
                                                    props.updateStatusScreen(newStatusScreen, newForm)
                                                }}
                                            >
                                                <Typography fontWeight={'bold'}>Pagar</Typography>

                                            </Button>
                                            : null}
                                    </Box>
                                    <Box>
                                        <Typography fontWeight={'bold'} fontSize={20}>ENDEREÇO</Typography>
                                        <Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>Rua:</Typography>
                                                <Typography>{e?.rua}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>Número:</Typography>
                                                <Typography>{e?.numero}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>Bairro:</Typography>
                                                <Typography>{e?.bairro}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>Cidade:</Typography>
                                                <Typography>{e?.cidade}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>Estado:</Typography>
                                                <Typography>{e?.estado}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Typography fontWeight={'bold'}>CEP:</Typography>
                                                <Typography >{e?.cep}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    {[1, 2, 3].includes(e?.status) ?
                                        <Box>
                                            <Button
                                                sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                                                onClick={async () => {
                                                    const modalInfo = {
                                                        open: true,
                                                        body: (
                                                            <Box sx={{ width: '100%', height: '100%', padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                <Typography fontWeight={'bold'} fontSize={20}>Reembolso</Typography>
                                                                <Typography >Nossa política de reembolsos valida todos os direitos do código de defesa do consumidor. Sendo assim será avaliada sua solicitação e reembolsado caso esteja de acordo com ela, preservando todos os seus direitos.</Typography>
                                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                                                                    <Typography>Você confirma a solicitação de reembolso?</Typography>
                                                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                                                        <Button
                                                                            sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                                                                            onClick={() => {
                                                                                props.closeModalInfo()
                                                                            }}
                                                                        >
                                                                            <Typography>Voltar</Typography>
                                                                        </Button>
                                                                        <Button
                                                                            sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                                                                            onClick={async () => {
                                                                                function FCallback(callback) {
                                                                                    if (callback.status === 200) {
                                                                                        const newData = [...data]
                                                                                        newData[idx].status = 7
                                                                                        newData[idx].info = callback?.apiReturn?.apiReturn?.info
                                                                                        newData[idx].description = callback.apiReturn.apiReturn.description
                                                                                        setData(newData)
                                                                                        const modalInfo = {
                                                                                            open: true,
                                                                                            body: (
                                                                                                <Box sx={{ width: '100%', height: '100%', padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                                                    <Typography fontWeight={'bold'} fontSize={20}>{callback?.apiReturn?.apiReturn?.info}</Typography>
                                                                                                    <Typography textAlign={'center'}>{callback?.apiReturn?.apiReturn?.description}</Typography>
                                                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                                                                                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                                                                                            <Button
                                                                                                                sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                                                                                                                onClick={() => {
                                                                                                                    props.closeModalInfo()
                                                                                                                }}
                                                                                                            >
                                                                                                                <Typography>Voltar</Typography>
                                                                                                            </Button>
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            )
                                                                                        }
                                                                                        props.updateModalInfo(modalInfo)
                                                                                        return
                                                                                    }
                                                                                    const modalInfo = {
                                                                                        open: true,
                                                                                        body: (
                                                                                            <Box sx={{ width: '100%', height: '100%', padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                                                <Typography fontWeight={'bold'} fontSize={20}>Reembolso</Typography>
                                                                                                <Typography textAlign={'center'}>Ocorreu um erro ao processar o reembolso. Não fique preocupado! Tente novamente mais tarde ou entre em contato conosco pelo WhatsApp e o ajudaremos.</Typography>
                                                                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                                                                                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                                                                                        <Button
                                                                                                            sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                                                                                                            onClick={() => {
                                                                                                                props.closeModalInfo()
                                                                                                            }}
                                                                                                        >
                                                                                                            <Typography>Voltar</Typography>
                                                                                                        </Button>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        )
                                                                                    }
                                                                                    props.updateModalInfo(modalInfo)

                                                                                }
                                                                                const body = {
                                                                                    token: user.data.token,
                                                                                    id: e?.id
                                                                                }
                                                                                await mainApi('pedidos_reembolsos/cliente', 'POST', body, FCallback)
                                                                            }}
                                                                        >
                                                                            <Typography>Confirmar</Typography>
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    }
                                                    props.updateModalInfo(modalInfo)
                                                }}
                                            >
                                                <Typography fontWeight={'bold'}>Reembolso</Typography>
                                            </Button>
                                        </Box>
                                        : null}
                                </Box>
                            </Collapse>
                        </Box>
                    )
                })
                    :
                    <Box sx={{ backgroundColor: theme.palette.background.paper, width: '100%', borderRadius: 1 }}>
                        <Box sx={{
                            borderBottom: 1, padding: 3, borderColor: theme.palette.background.default, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column',
                                gap: 2
                            },
                        }}>
                            <Typography fontWeight={'bold'}>Ainda não existem pedidos</Typography>
                            <Button
                                component={Link}
                                to='../carrinho'
                                sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                                onClick={() => {

                                }}
                            >
                                <Typography fontWeight={'bold'}>Ir para carrinho</Typography>
                            </Button>
                        </Box>
                    </Box>
                }
            </Box>
        </Box >
    )
}