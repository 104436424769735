
import React, { useState } from "react";
import { useTheme, styled } from '@mui/material/styles';
import { Box, Typography, Button, Icon } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export default props => {
    const theme = useTheme();
    const [modalAtive, setModalAtive] = useState(false)
    function closeModal() {
        setModalAtive(false)
    }
    const drawerWidth = 240;
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 0,
        backgroundColor: theme.palette.background.default,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));
    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });
    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: theme.breakpoints.down('sm') ? '100%' : drawerWidth,
            backgroundColor: theme.palette.background.default,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );
    const [open, setOpen] = React.useState(true);

    const handleDrawerClose = () => {
        props.changeLeftOpen(!props.open);
    };
    return (
        <Drawer className="drawer-minha-conta" variant="permanent" open={props.open}>
            <DrawerHeader>
                <Button sx={{ width: '100%', justifyContent: 'inherit', right: 3, '&:hover': { backgroundColor: 'transparent' } }} onClick={handleDrawerClose}>
                    {props.open ?
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5, justifyContent: 'center' }}>
                            <MenuIcon sx={{ color: theme.palette.secondary.main, fontSize: 25 }} />
                            <Typography fontWeight={'bold'} color={theme.palette.text.primary} sx={{ textTransform: 'none', top: 2, position: 'relative' }}>Minha Conta</Typography>
                        </Box>
                        : null}
                    <Icon sx={{ position: 'relative', bottom: 2 }}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ color: theme.palette.secondary.main, fontSize: 25 }} /> : props.open ? <ChevronLeftIcon sx={{ color: theme.palette.secondary.main, fontSize: 25 }} /> : <MenuIcon sx={{ color: theme.palette.secondary.main, fontSize: 25 }} />}
                    </Icon>
                </Button>
            </DrawerHeader>
            <Divider />
            <List sx={{ backgroundColor: theme.palette.background.default, paddingTop: 0, paddingBottom: 0 }}>
                {props.screens.map((e, idx) => (
                    <ListItem key={idx} disablePadding sx={{ display: 'block', backgroundColor: props.activeScreen === idx ? theme.palette.secondary.rgba : 'transparent' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: props.open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={() => {
                                props.updateScreen(idx)
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: props.open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {e.id === 1 ? <ContactPageIcon sx={{ color: theme.palette.secondary.main, fontSize: 25 }} /> : e.id === 2 ? <ShoppingCartIcon sx={{ color: theme.palette.secondary.main, fontSize: 25 }} /> : <LocalShippingIcon sx={{ color: theme.palette.secondary.main, fontSize: 25 }} />}
                            </ListItemIcon>
                            <ListItemText primary={e.nome} sx={{ opacity: props.open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Drawer>
    )
}