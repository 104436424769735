import React, { useState, useContext, useRef, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Avatar, List, Grid, ListItem, Collapse } from '@mui/material';
import useWindowDimensions from "../../components/useWindowDimensions";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { Product } from "../../contexts/product";
import { ModalDepoimento } from "../../components/modalDepointo";

import { Pixel } from "../../components/pixel";
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/grid";
import 'swiper/swiper-bundle.css';
import ListProducts from "../../components/listProducts";

export default props => {
    const theme = useTheme()
    const screenHeight = useWindowDimensions().height
    const screenWidth = useWindowDimensions().width
    const produto = useContext(Product)
    const [controler, setController] = useState(null)
    const depoimentoInitial = {
        data: null,
        active: false
    }
    const [depoimento, setDepoimento] = useState(depoimentoInitial)
    document.title = 'VitLov'
    const params = {
        loop: true,
        autoplay: {
            delay: 6000, // tempo de transição entre os slides em milissegundos
            disableOnInteraction: true
        },
        modules: [Pagination, Navigation, Autoplay, EffectFade],
        effect: 'fade',
        pagination: {
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '" style="background-color: ' + theme.palette.secondary.main + ';"></span>'; // Altere "red" para a cor desejada
            },
        },
    };
    const buttonStyle = {
        backgroundColor: '#19FF88',
        padding: 2,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#15DC75',
        }
    }
    const buttonDepoimentoStyle = {
        backgroundColor: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 0,
        '&:hover': { backgroundColor: theme.palette.secondary.rgba },
    }
    function closeModalDepoimento() {
        setDepoimento(depoimentoInitial)
    }
    const scrollToTarget = () => {
        const target = document.getElementById('produtos');
        if (target) {
            target.scrollIntoView({ behavior: 'smooth' });
        }
    };
    Pixel()
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default }} height={'auto'}>

            <Box sx={{
                width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative'
            }}>
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        filter: 'blur(50px)',
                        backgroundImage: 'url(./assets/images/home/section1/bg.png)',
                        backgroundSize: 'cover',
                        zIndex: 1,
                    }}
                />
                <Box sx={{
                    display: 'flex', zIndex: 10, justifyContent: 'center', alignItems: 'center', position: 'relative', width: '70%', height: 800, filter: 'unset', [theme.breakpoints.down('lg')]: {
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        height: 'auto'
                    },
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img className="vitLovConstrucao" src="../assets/images/home/section1/vitLov.png" alt="vitlov" width={400} />
                    </Box>
                    <Box sx={{
                        zIndex: 100, width: '100%', padding: 6, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, justifyContent: 'center',
                        [theme.breakpoints.down('md')]: {
                            paddingBottom: '20px !important'
                        },
                    }}>
                        <Typography fontSize={50} fontWeight={'bold'} textAlign={'center'} sx={{
                            [theme.breakpoints.down('md')]: {
                                paddingBottom: '40px !important',
                                fontSize: 35,
                            },
                        }}>POTENCIALIZE SUA VIDA SEXUAL</Typography>
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', gap: 1, flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center',
                            [theme.breakpoints.down('md')]: {
                                flexWrap: 'wrap',
                            },
                        }}>
                            <Box sx={{
                                display: 'flex', gap: 0
                            }}>
                                <Typography fontSize={30} fontWeight={'800'} sx={{ color: theme.palette.secondary.main }}>VIT</Typography>
                                <Typography fontSize={30} fontWeight={'800'} sx={{ color: theme.palette.primary.main }}>LOV</Typography>
                            </Box>
                            <Typography fontSize={30} fontWeight={'bold'} sx={{
                                textAlign: 'center',
                                [theme.breakpoints.down('lg')]: {
                                    textAlign: 'center'
                                },
                            }}>possui substâncias que irão:</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography fontSize={30} fontWeight={'bold'} textAlign={'center'} sx={{
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 20,
                                },
                            }}>Aumentar sua</Typography>
                            <Typography fontSize={30} fontWeight={'800'} sx={{
                                color: theme.palette.secondary.main, [theme.breakpoints.down('md')]: {
                                    fontSize: 20,
                                },
                            }}>DISPOSIÇÃO</Typography>
                            <Typography fontSize={30} fontWeight={'bold'} sx={{
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 20,
                                },
                            }} >sexual</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography fontSize={30} fontWeight={'bold'} textAlign={'center'} sx={{
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 20,
                                },
                            }}>Aumentar sua</Typography>
                            <Typography fontSize={30} fontWeight={'800'} sx={{
                                color: theme.palette.tertiary.main, [theme.breakpoints.down('md')]: {
                                    fontSize: 20,
                                },
                            }}>ENERGIA</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography fontSize={30} fontWeight={'bold'} textAlign={'center'} sx={{
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 20,
                                },
                            }}>Aumentar sua</Typography>
                            <Typography fontSize={30} fontWeight={'800'} sx={{
                                color: theme.palette.primary.main, [theme.breakpoints.down('md')]: {
                                    fontSize: 20,
                                },
                            }}>LIBIDO</Typography>
                        </Box>
                        <Button
                            className="animatedButton"
                            sx={[buttonStyle, {
                                marginTop: 2,
                                [theme.breakpoints.down('md')]: {
                                    display: 'none',
                                    padding: 1,
                                }
                            }]} onClick={scrollToTarget}>
                            <Typography fontSize={25} fontWeight={'700'} sx={{
                                color: theme.palette.background.default,
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 15,
                                }
                            }}>Comprar meu VITLOV</Typography>
                        </Button>
                    </Box>
                    <Button
                        className="animatedButton"
                        sx={[buttonStyle, {
                            display: 'none',
                            marginTop: 2,
                            [theme.breakpoints.down('md')]: {
                                display: 'block',
                                width: '100%',
                                marginBottom: '30px'
                            }
                        }]} onClick={scrollToTarget}>
                        <Typography fontSize={25} fontWeight={'700'} sx={{
                            color: theme.palette.background.default,
                            [theme.breakpoints.down('md')]: {
                                fontSize: 20,
                            }
                        }}>Comprar meu VITLOV</Typography>
                    </Button>

                </Box>
            </Box>
            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', backgroundImage: 'url(./assets/images/home/section2/bg.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '700px', [theme.breakpoints.down('lg')]: {
                    height: 'auto',
                    justifyContent: 'flex-start',
                    paddingTop: 5,
                    paddingBottom: 5,
                    gap: 2
                },
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Box sx={{
                        display: 'flex', gap: 3, zIndex: 10, justifyContent: 'center', alignItems: 'center', position: 'relative', width: '70%', [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column',
                        },
                    }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                            <Typography fontSize={30} fontWeight={'bold'} textAlign={'center'} sx={{
                                textTransform: '',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 20,
                                },
                            }}><i>paullinia cupana</i></Typography>
                            <img alt="paullinia cupana" src="../assets/images/home/section2/paulina_cupana.png" />
                            <Typography fontSize={17} textAlign={'center'}>A <i>paullinia cupana</i>, é uma planta amazônica cujas sementes, ricas em cafeína, são conhecidas por seu potencial como estimulante de libido, contribuindo para o aumento do desejo sexual.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                            <Typography fontSize={30} fontWeight={'bold'} textAlign={'center'} sx={{
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 20,
                                },
                            }}>Ácido Aspártico</Typography>
                            <img src="../assets/images/home/section2/acido_aspartico.png" alt="Ácido Aspártico" />
                            <Typography fontSize={17} textAlign={'center'}>O ácido aspártico, um aminoácido, é reconhecido por suas propriedades como estimulante de libido, devido à sua associação com a regulação hormonal e a melhora da função sexual.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                            <Typography fontSize={30} fontWeight={'bold'} textAlign={'center'} sx={{
                                [theme.breakpoints.down('md')]: {
                                    fontSize: 20,
                                },
                            }}>Arginina</Typography>
                            <img src="../assets/images/home/section2/arginina.png" alt="Arginina" />
                            <Typography fontSize={17} textAlign={'center'}>A arginina, um aminoácido essencial, é amplamente reconhecida por seu papel como estimulante de libido, pois promove a circulação sanguínea saudável e ajuda a melhorar a função sexual.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Button className="animatedButton" sx={[buttonStyle]} onClick={scrollToTarget}>
                    <Typography fontSize={25} fontWeight={'700'} sx={{
                        color: theme.palette.background.default, [theme.breakpoints.down('md')]: {
                            fontSize: 20,
                        }
                    }}>Comprar meu VITLOV</Typography>
                </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Swiper {...params}>
                    <SwiperSlide><img src={screenWidth < 700 ? "../assets/images/home/slider/unidades-vendidas.png" : "../assets/images/home/slider/unidades-vendidas-full.png"} alt="vitlov suplement" style={{ objectFit: 'contain', width: '100%', height: 'auto' }} /></SwiperSlide>
                    <SwiperSlide><img src={screenWidth < 700 ? "../assets/images/home/slider/suplemento.jpg" : "../assets/images/home/slider/suplemento-full.jpg"} alt="vitlov suplement" style={{ objectFit: 'contain', width: '100%', height: 'auto' }} /></SwiperSlide>
                    <SwiperSlide><img src={screenWidth < 700 ? "../assets/images/home/slider/indicacao.jpg" : "../assets/images/home/slider/indicacao-full.png"} alt="vitlov indicacao" style={{ objectFit: 'contain', width: '100%', height: 'auto' }} /></SwiperSlide>
                </Swiper>
            </Box>

            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: 700, backgroundImage: 'url(./assets/images/home/section4/bg.png)', backgroundRepeat: 'no-repeat', [theme.breakpoints.down('md')]: {
                    height: 'auto',
                },
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', gap: 2, zIndex: 10, justifyContent: 'center', alignItems: 'center', position: 'relative', width: '70%',
                    [theme.breakpoints.down('sm')]: {
                        width: '90%',
                        justifyContent: 'flex-start'
                    },

                }}>
                    <Box sx={{ padding: 4 }}>
                        <Typography fontSize={50} fontWeight={'bold'} textAlign={'center'} sx={{
                            [theme.breakpoints.down('md')]: {
                                fontSize: 35,
                            },
                        }}>DÚVIDAS SOBRE COMPRAR PELA INTERNET?</Typography>
                        <Typography fontSize={30} textAlign={'center'} sx={{
                            [theme.breakpoints.down('md')]: {
                                fontSize: 20,
                            },
                        }}>Comprar VITLOV é fácil,
                            prático e seguro</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex', gap: 5, justifyContent: 'center', alignItems: 'center', [theme.breakpoints.down('md')]: {
                                flexDirection: 'column'
                            }
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2 }}>
                            <Box sx={{ background: '#fff', height: 200, width: 200, borderRadius: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="../assets/images/home/section4/ship_icon.png" alt="entrega icone" />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, height: 180, width: 180, borderRadius: 2, background: '#fff' }}>
                                <Typography sx={{ color: theme.palette.background.default, fontWeight: 'bold', fontSize: 15, textAlign: 'center' }}>Entregas <span style={{ color: theme.palette.primary.main }}>para todo o Brasil</span> via correios ou transportadoras parceiras.</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2 }}>
                            <Box sx={{ background: '#fff', height: 200, width: 200, borderRadius: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="../assets/images/home/section4/mercadoP_icon.png" alt="mercado pago icone" />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, height: 180, width: 180, borderRadius: 2, background: '#fff' }}>
                                <Typography sx={{ color: theme.palette.background.default, fontWeight: 'bold', fontSize: 15, textAlign: 'center' }}>Sua compra está protegida e segurada pelo <span style={{ color: theme.palette.secondary.main }}>Mercado Pago.</span></Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2 }}>
                            <Box sx={{ background: '#fff', height: 200, width: 200, borderRadius: 25, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="../assets/images/home/section4/ssl_icon.png" alt="ssl icone" />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, height: 180, width: 180, borderRadius: 2, background: '#fff' }}>
                                <Typography sx={{ color: theme.palette.background.default, fontWeight: 'bold', fontSize: 15, textAlign: 'center' }}>Todas as transações são protegidas com <span style={{ color: theme.palette.tertiary.main }}>certificado SSL</span> e os dados utilizados são todos <span style={{ color: theme.palette.tertiary.main }}>criptografados.</span></Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box id="produtos" sx={{
                display: 'flex', flexDirection: 'column', marginBottom: 10, justifyContent: 'center', alignItems: 'center', width: '100%', height: 750, background: theme.palette.background.default, [theme.breakpoints.down('md')]: {
                    height: 'auto',
                },
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', gap: 2, zIndex: 10, justifyContent: 'center', alignItems: 'center', position: 'relative', width: '60%',
                    [theme.breakpoints.down('sm')]: {
                        width: '90%',
                        justifyContent: 'flex-start'
                    },
                    [theme.breakpoints.down('md')]: {
                        width: '80%',
                    },

                }}>
                    <Box sx={{ padding: 4 }}>
                        <Typography fontSize={50} fontWeight={'bold'} textAlign={'center'} sx={{
                            [theme.breakpoints.down('md')]: {
                                fontSize: 35,
                            },
                        }}>COMBOS</Typography>
                        <Typography fontSize={30} textAlign={'center'} sx={{
                            [theme.breakpoints.down('md')]: {
                                fontSize: 20,
                            },
                        }}>Recomendamos a compra de 3 a 6 caixas para o tratamento completo</Typography>
                    </Box>
                    <ListProducts home={true} />
                </Box>
            </Box>
            {produto?.data?.perguntas_respostas ?
                <Box sx={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: 5, alignItems: 'center', width: '100%', height: 'auto', background: theme.palette.secondary.main, [theme.breakpoints.down('md')]: {
                        height: 'auto',
                    },
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', gap: 2, zIndex: 10, justifyContent: 'center', alignItems: 'center', position: 'relative', width: '70%',
                        [theme.breakpoints.down('sm')]: {
                            width: '90%',
                            justifyContent: 'flex-start'
                        },
                    }}>
                        <Typography fontSize={50} fontWeight={'bold'} textAlign={'center'} sx={{
                            [theme.breakpoints.down('md')]: {
                                fontSize: 35,
                            },
                        }}>DÚVIDAS FREQUENTES</Typography>
                        <List >
                            <Grid container spacing={2}>
                                {produto.data.perguntas_respostas.map((e, idx) => {
                                    return (
                                        <Grid item xs={12} sm={6} key={e?.id} sx={{ width: '100%' }}> {/* Cria duas colunas, cada uma ocupando 50% da largura */}
                                            <Button
                                                sx={{
                                                    backgroundColor: theme.palette.background.default, padding: 2, width: '100%', '&:hover': {
                                                        backgroundColor: theme.palette.background.paper
                                                    }
                                                }}
                                                onClick={() => {
                                                    if (controler === idx) {
                                                        setController(null)
                                                        return
                                                    }
                                                    setController(idx)
                                                }}
                                            >
                                                <ListItem>
                                                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>{e?.pergunta}</Typography>
                                                </ListItem>
                                            </Button>
                                            <Collapse in={controler === idx} timeout="auto" unmountOnExit>
                                                <Box sx={{ background: '#fff', padding: 2 }}>
                                                    <Typography sx={{ color: theme.palette.text.inverse }}>{e?.resposta}</Typography>
                                                </Box>
                                            </Collapse>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </List>
                    </Box >
                </Box >
                : null}

            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: 350, backgroundImage: 'url(./assets/images/home/section6/bg.png)', backgroundRepeat: 'no-repeat', [theme.breakpoints.down('md')]: {
                    height: 'auto',
                    padding: 2
                },
            }}>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', gap: 2, zIndex: 10, justifyContent: 'center', alignItems: 'center', position: 'relative', width: '50%',
                    [theme.breakpoints.down('sm')]: {
                        width: '90%',
                        justifyContent: 'flex-start'
                    },
                    [theme.breakpoints.down('md')]: {
                        width: '80%',
                    },

                }}>
                    <Box sx={{ padding: 2 }}>
                        <Typography fontSize={30} fontWeight={'bold'} textAlign={'center'} sx={{
                            [theme.breakpoints.down('md')]: {
                                fontSize: 25,
                            },
                        }}>QUER FALAR COM UM DE NOSSOS ATENDENTES?</Typography>
                        <Typography fontSize={20} textAlign={'center'} sx={{
                            [theme.breakpoints.down('md')]: {
                                fontSize: 15,
                            },
                        }}>Se você possui dúvidas e quer falar direto com uma de nossos consultores, disponibilizamos um Whatsapp Exclusivo. Para acessar clique no botão abaixo.</Typography>
                    </Box>
                    <Button className="animatedButton" sx={[buttonStyle, {
                        [theme.breakpoints.down('md')]: {
                            padding: 1
                        },
                    }]} onClick={() => window.open('https://api.whatsapp.com/send?phone=5549999680248', '_blank')}>
                        <Typography fontSize={20} fontWeight={'700'} sx={{ color: theme.palette.background.default }}>Chamar no WhatsApp</Typography>
                    </Button>
                </Box>
            </Box>
            <ModalDepoimento data={depoimento.data} ative={depoimento.active} closeModal={closeModalDepoimento} />
        </Box >
    )
}