import React, { useContext, useState, useEffect, useMemo } from "react"
import { Box, ListItemIcon, ListItemButton, ListItem, ListItemText, Typography, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Product } from "../../contexts/product";

export default props => {
    const theme = useTheme();
    const product = useContext(Product)
    return useMemo(() => {
        if (props.form.entrega.load) {
            if (Array.isArray(product?.data?.entrega)) {
                return (
                    <Box width={'100%'}>
                        {
                            product?.data?.entrega.map((e, idx) => {
                                return (
                                    <Box key={idx} sx={{
                                        marginTop: idx === 0 ? 2 : 0,
                                        borderBottom: 2,
                                        borderColor: theme.palette.background.paper
                                    }}>
                                        <ListItem sx={{ padding: 0 }}>
                                            <ListItemButton onClick={() => {
                                                const newForm = { ...props.form }
                                                newForm.entrega = {
                                                    id: e?.id,
                                                    preco: e?.preco,
                                                    prazo: {
                                                        type: 1,
                                                        value: e?.prazo
                                                    },
                                                    load: true,
                                                    name: e?.nome
                                                }
                                                props.updateForm(newForm)
                                            }}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={props.form.entrega.id === e?.id}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        sx={{
                                                            color: theme.palette.secondary.main,
                                                            '&.Mui-checked': {
                                                                color: theme.palette.secondary.main,
                                                            },
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={e?.nome} primaryTypographyProps={{ style: { fontWeight: 'bold' } }} sx={{ fontWeight: 'bold' }} />
                                                <Box sx={{ paddingRight: 2 }}>
                                                    <Box display={'flex'} gap={1} left={'8%'} position={'relative'}>
                                                        <Typography fontWeight={'bold'}>Entrega: </Typography>
                                                        <Typography>{'Dia da postagem + ' + e?.prazo + ' dias.'}</Typography>
                                                    </Box>
                                                    <Box display={'flex'} gap={1} left={'8%'} position={'relative'}>
                                                        <Typography fontWeight={'bold'}>Preço: </Typography>
                                                        <Typography>{'R$' + e?.preco}</Typography>
                                                    </Box>
                                                </Box>
                                            </ListItemButton>
                                        </ListItem>
                                    </Box>
                                )
                            })
                        }
                    </Box >
                )
            }
            else return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography>Nenhuma opção de entrega encontrada para sua região</Typography>
                </Box>
            )
        }
        else return null
    }, [props.form, props.open])
}
