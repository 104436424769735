export function TestaCPF(strCPF) {
    if ([undefined, null, ''].includes(strCPF)) return false

    //Extrai somente os números
    function limparcpf(cpf) {   // (/[^0-9]/, '')
        let newCpf = cpf.split('.').join('');
        newCpf = newCpf.split('-').join('');
        return newCpf

    };
    let cpf = limparcpf(strCPF);
    // Verifica se foi informado todos os digitos corretamente
    if (cpf.length !== 11) return false;

    // Verifica se foi informada uma sequência de digitos repetidos.Ex: 111.111.111 - 11
    let newRegex = new RegExp(/^(\d\d)\d{0,10}\1$/) ///^ (d)1+$/
    if (newRegex.test(cpf) === true) return false;
    // Faz o calculo para validar o CPF
    let c = 0
    let d = 0
    let t = 9
    for (t = 9; t < 11; t++) {
        for (d = 0, c = 0; c < t; c++) {
            d += cpf[c] * ((t + 1) - c);
        }
        d = ((10 * d) % 11) % 10;

        if (cpf[c] != d) {
            return false;
        }
    }
    return true;
}

export const TestaEMAIL = (email) => {
    const valida = (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))
    return valida
}

export const TestaCELULAR = (celular) => {
    var expressao = /\(\d{2,}\) \d{4,}\-\d{4}/g;
    var regex = new RegExp(expressao);
    const newCelular = celular.replace(' ', '')
    return regex.test(celular);
}