import { createContext, useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { decrypt, encrypt } from '../functions';
import mainApi from '../apis/mainApi';


export const Product = createContext()

export default props => {
    const cookies = new Cookies()
    const [data, setData] = useState({
        id: 1,
        name: 'VitLov',
        description: 'VitLov suplemento alimentar',
        cep_origem: '89665000',
        activeItem: {},
        prices: [
            {
                qtd: 1,
                servicos: ['04014', '04510'],
                image: '../../assets/images/product/main.png',
                price: 150.00,
                peso: '1',
                nVlComprimento: '20',
                nVlAltura: '20',
                nVlLargura: '20',
                nVlDiametro: '10',
                formato: '1',
            },
            {
                qtd: 2,
                servicos: ['04014', '04510'],
                image: '../../assets/images/product/main.png',
                price: 169.90,
                peso: '3',
                nVlComprimento: '30',
                nVlAltura: '20',
                nVlLargura: '30',
                nVlDiametro: '15',
                formato: '1',
            },
            {
                qtd: 3,
                price: 199.90,
                servicos: ['04014', '04510'],
                image: '../../assets/images/product/main.png',
                peso: '1',
                nVlComprimento: '40',
                nVlAltura: '20',
                nVlLargura: '40',
                nVlDiametro: '20',
                formato: '1',
            },
        ],
        entregas: [
            {
                id: 1,
                name: 'Frete Grátis',
                conditions: {
                    estados: [],
                    preco: [{
                        preco: 120,
                        price: 0.00
                    }
                    ],
                    quantidade: [],
                },
                price: 0.00
            },
            {
                id: 2,
                name: 'Correios',
                servicos: ['04014', '04510'],
                formato: '1',
                peso: '1',
                nVlComprimento: '20',
                nVlAltura: '20',
                nVlLargura: '20',
                nVlDiametro: '0',
                options: [],
                conditions: {
                    estados: [],
                    cidades: [],
                    preco: [],
                    quantidade: []
                },
                status: 1
            },
            {
                id: 3,
                name: 'JetLog',
                conditions: {
                    estados: [],
                    cidades: [],
                    preco: [],
                    quantidade: []
                },
                price: 30.00
            }
        ]
    })
    async function getData() {
        function FCallback(callback) {
            if (callback.status === 200) {
                const getCart = cookies.get('cart')
                if (getCart) {
                    callback.apiReturn.apiReturn.activeItem = getCart
                }
                setData(callback?.apiReturn?.apiReturn)
                return
            }
            setData(false)
        }
        await mainApi('initialApplication', 'GET', null, FCallback)
    }

    useEffect(() => {
        getData()
    }, [])

    function deleteActiveItem() {
        const newData = { ...data }
        newData.activeItem = {}
        setData(newData)
    }
    function updateProduct(data, FCallback) {
        cookies.set('cart', data.activeItem)
        setData(data)
        if (FCallback) {
            FCallback()
        }
    }


    return (
        <Product.Provider value={{ data, updateProduct, deleteActiveItem }}>
            {props.children}
        </Product.Provider>
    )
}
