import React from "react";
import { Box, Typography, Modal, CircularProgress, useTheme, Button } from "@mui/material"
import moment from 'moment'


export function ModalCupom(props) {
    const theme = useTheme()
    const couponStyle = {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: "20px",
        width: "250px",
        textAlign: "center",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
    };
    const titleStyle = {
        fontSize: "1.5rem",
        fontWeight: "bold",
        margin: "10px 0",
    };

    const detailStyle = {
        fontSize: "1rem",
        margin: "5px 0",
    };
    return (
        <Modal
            open={props.ative}
            onClose={() => props.closeModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: 300,
                width: '30%',
                transform: 'translate(-50%, -50%)',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                outline: 'none',
            }}>
                <Box
                    style={couponStyle}
                    key={props?.data?.id}
                >
                    <Typography sx={titleStyle}>{props.data.tipo_nome}</Typography>
                    <Box sx={detailStyle}>Código: {props.data.codigo}</Box>
                    {props.data.parceiro_id && (
                        <Box sx={detailStyle}>Parceiro: {props.data.parceiro_nome}</Box>
                    )}
                    <Box sx={detailStyle}>
                        Validade: {moment(props.data.validade, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </Box>
                    <Box sx={detailStyle}>Valor: {props.data.valor}</Box>
                    <Box sx={detailStyle}>Disponível: {props.data.quantidade - props.data.usados}</Box>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', marginTop: 5 }}>
                        <Button onClick={() => props.closeModal()} sx={{ maxWidth: 300, fontWeight: 'bold', backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}>Cancelar</Button>
                        <Button onClick={() => props.closeModal(props.data, true)} sx={{ maxWidth: 300, fontWeight: 'bold', backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}>Usar</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

