import React, { useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Collapse, ListItemIcon, ListItemButton, ListItemText, TextField, Typography, Button, Checkbox } from '@mui/material';
import { cepMask } from "../../components/masks";
import mainApi from "../../apis/mainApi";
import { ModalLoading } from "../../components/modal";
import { SnackBarAlert, SnackBarSucess } from "../../components/snackbar";
import HomeIcon from '@mui/icons-material/Home';
import { Product } from "../../contexts/product";
import { User } from "../../contexts/user";

export default props => {
    const theme = useTheme();
    const product = useContext(Product)
    const user = useContext(User)
    const [modalAtive, setModalAtive] = useState(false)
    const [snackBar, setSnackBar] = useState({
        id: 1,
        open: false,
        message: ""
    })
    const [errors, setErrors] = useState({
        cep: false
    })
    function closeModal() {
        setModalAtive(false)
    }
    function closeSnack() {
        const x = { ...snackBar }
        x.open = false
        setSnackBar(x)
    }
    async function getEntrega() {
        if (!product?.data?.activeItem?.preco) {
            const x = { ...snackBar }
            x.open = true
            x.id = 1
            x.message = 'Seleciona um produto na etapa anterior para prosseguir'
            props.setSnackBar(x)
            setModalAtive(false)
            return
        }
        function FCalback(callback) {
            const newCorreios = { ...product.data }
            if (callback.status === 200) {
                newCorreios.entrega = callback.apiReturn.apiReturn
                product.updateProduct(newCorreios)
                const newForm = { ...props.form }
                newForm.entrega.load = true
                props.updateForm(newForm)
                props.changeOpen(1)
                closeModal()
            }
        }
        const body = {
            //sCepOrigem: product?.data?.cep_origem.toString(),
            sCepDestino: props?.form?.cep.replace('-', ''),
            //nCdServico: product?.data?.activeItem?.nCdServico,
            nVlPeso: product?.data?.activeItem?.nVlPeso.toString(),
            nCdFormato: product?.data?.activeItem?.nCdFormato.toString(),
            nVlComprimento: product?.data?.activeItem?.nVlComprimento.toString(),
            nVlAltura: product?.data?.activeItem?.nVlAltura.toString(),
            nVlLargura: product?.data?.activeItem?.nVlLargura.toString(),
            nVlDiametro: product?.data?.activeItem?.nVlDiametro.toString(),
            nvlnVlDeclarado: product?.data?.activeItem?.preco.toString(),
            produto: {
                preco: product.data.activeItem.preco,
                quantidade: product.data.activeItem.quantidade
            },
            endereco: {
                id: user.data.id,
                rua: props.form.rua,
                numero: props.form.numero,
                bairro: props.form.bairro,
                cidade: props.form.cidade,
                estado: props.form.estado,
                cep: props.form.cep,
                complemento: props.form.complemento ? props.form.complemento : '',
                save: props.form.salvarEndereco
            }
        }
        await mainApi('correios/calcularPreco', 'POST', body, FCalback)
    }

    return (
        <Box sx={{ height: '100%', backgroundColor: theme.palette.background.default, width: '100%' }}>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
                    <HomeIcon sx={{ fontSize: 80, alignSelf: 'center', color: theme.palette.secondary.main }} />
                    <Typography variant="h5" textAlign={'center'} fontWeight={'bold'}>Preencha com as informações para entrega</Typography>
                </Box>
                <Box height={30} width={'100%'} bgcolor={theme.palette.background.paper} />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', padding: 5, rowGap: 2 }}>
                    <Box sx={{
                        width: '49%',
                        [theme.breakpoints.down('md')]: {
                            width: '100%'
                        },
                    }}>
                        <TextField
                            required
                            fullWidth
                            value={props.form.cep}
                            id="cep"
                            name="cep"
                            label="Cep"
                            error={errors.cep === true}
                            onChange={(value) => {
                                if (props.form.rua) {
                                    const x = { ...props.form }
                                    x.rua = ''
                                    x.numero = ''
                                    x.bairro = ''
                                    x.cidade = ''
                                    x.estado = ''
                                    x.cep = cepMask(value.target.value)
                                    props.updateForm(x)
                                    return
                                }
                                const x = { ...props.form }
                                x.cep = cepMask(value.target.value)
                                props.updateForm(x)
                            }}
                            onBlur={(value) => {
                                if (value.target.value === '') {
                                    return
                                }
                                if (value.target.value.length < 9) {
                                    const newErrors = { ...errors }
                                    newErrors.cep = true
                                    setErrors(newErrors)
                                    return
                                }
                                setModalAtive(true)
                                function callbackReturn(callback) {
                                    setModalAtive(false)
                                    if (callback.status !== 200) {
                                        const x = { ...snackBar }
                                        x.open = true
                                        x.id = 1
                                        x.message = callback?.apiReturn?.message ? callback?.apiReturn?.message : 'Não foi possível buscar o cep'
                                        props.setSnackBar(x)
                                        const newErrors = { ...errors }
                                        newErrors.cep = true
                                        setErrors(newErrors)
                                        return
                                    }
                                    const newErrors = { ...errors }
                                    newErrors.cep = false
                                    setErrors(newErrors)
                                    const y = { ...snackBar }
                                    y.open = true
                                    y.id = 2
                                    y.message = 'Cep encontrado com sucesso'
                                    props.setSnackBar(y)
                                    const apiReturn = callback?.apiReturn?.apiReturn
                                    const x = { ...props.form }
                                    x.cidade = apiReturn?.localidade ? apiReturn.localidade : x.cidade
                                    x.rua = apiReturn?.logradouro ? apiReturn.logradouro : x.rua
                                    x.bairro = apiReturn?.bairro ? apiReturn.bairro : x.bairro
                                    x.estado = apiReturn?.uf ? apiReturn.uf : x.estado
                                    props.updateForm(x)
                                }
                                mainApi('correios/cep', 'POST', { cep: value.target.value }, callbackReturn)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.secondary,
                                    borderColor: '#eee'
                                }
                            }}
                            inputProps={{
                                style: {
                                    backgroundColor: theme.palette.background.paper,
                                    borderRadius: 5,
                                }
                            }}
                        />
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} gap={2} sx={{
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column'
                        },
                    }}>
                        <TextField
                            fullWidth
                            margin="none"
                            disabled={errors.cep === true ? true : props.form.cep === '' ? true : false}
                            required
                            value={props.form.rua}
                            id="rua"
                            name="rua"
                            label="Rua"
                            onChange={(value) => {
                                const x = { ...props.form }
                                x.rua = value.target.value
                                props.updateForm(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.secondary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: errors.cep === true || props.form.cep === '' ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                        <TextField
                            margin="none"
                            required
                            value={props.form.numero}
                            disabled={errors.cep === true ? true : props.form.cep === '' ? true : false}
                            id="numero"
                            name="numero"
                            label="Número"
                            onChange={(value) => {
                                const x = { ...props.form }
                                x.numero = value.target.value.replace(/\D/g, "")
                                props.updateForm(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.secondary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: errors.cep === true || props.form.cep === '' ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />

                    </Box>
                    <TextField
                        margin="none"
                        required
                        value={props.form.bairro}
                        disabled={errors.cep === true ? true : props.form.cep === '' ? true : false}
                        id="bairro"
                        name="bairro"
                        label="Bairro"
                        onChange={(value) => {
                            const x = { ...props.form }
                            x.bairro = value.target.value
                            props.updateForm(x)
                        }}
                        InputLabelProps={{
                            style: {
                                color: theme.palette.text.secondary,
                                borderColor: '#eee'
                            }
                        }}
                        autoComplete=""
                        inputProps={{
                            style: {
                                backgroundColor: errors.cep === true || props.form.cep === '' ? theme.palette.background.default : theme.palette.background.paper,
                                borderRadius: 5
                            }
                        }}
                    />
                    <Box display={'flex'} flexDirection={'row'} gap={2} sx={{
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column'
                        },
                    }}>
                        <TextField
                            fullWidth
                            margin="none"
                            disabled={errors.cep === true ? true : props.form.cep === '' ? true : false}
                            required
                            value={props.form.cidade}
                            id="cidade"
                            name="cidade"
                            label="Cidade"
                            onChange={(value) => {
                                const x = { ...props.form }
                                x.cidade = value.target.value.replace(/[^a-zA-Z]+/g, '')
                                props.updateForm(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.secondary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: errors.cep === true || props.form.cep === '' ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                        <TextField
                            margin="none"
                            required
                            value={props.form.estado}
                            disabled={errors.cep === true ? true : props.form.cep === '' ? true : false}
                            id="estado"
                            name="estado"
                            label="Estado"
                            onChange={(value) => {
                                const x = { ...props.form }
                                x.estado = value.target.value.replace(/[^a-zA-Z]+/g, '')
                                props.updateForm(x)
                            }}
                            InputLabelProps={{
                                maxLength: 2,
                                style: {
                                    color: theme.palette.text.secondary,
                                    borderColor: '#eee',
                                    textTransform: 'uppercase',
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: errors.cep === true || props.form.cep === '' ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />

                    </Box>
                    <TextField
                        margin="none"
                        value={props.form.complemento}
                        disabled={errors.cep === true ? true : props.form.cep === '' ? true : false}
                        id="complemento"
                        name="complemento"
                        label="Complemento"
                        onChange={(value) => {
                            const x = { ...props.form }
                            x.complemento = value.target.value
                            props.updateForm(x)
                        }}
                        InputLabelProps={{
                            style: {
                                color: theme.palette.text.secondary,
                                borderColor: '#eee'
                            }
                        }}
                        autoComplete=""
                        inputProps={{
                            style: {
                                backgroundColor: errors.cep === true || props.form.cep === '' ? theme.palette.background.default : theme.palette.background.paper,
                                borderRadius: 5
                            }
                        }}
                    />
                    {!user?.data?.token ? null : [undefined, null, ''].includes(user.data.rua) ?
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Button onClick={() => {
                                const x = { ...props.form }
                                x.salvarEndereco = !x.salvarEndereco
                                props.updateForm(x)
                            }}>
                                <Checkbox
                                    checked={props.form.salvarEndereco}
                                    onChange={() => {
                                        const x = { ...props.form }
                                        x.salvarEndereco = !x.salvarEndereco
                                        props.updateForm(x)
                                    }}
                                    sx={{
                                        color: theme.palette.secondary.main,
                                        '&.Mui-checked': {
                                            color: theme.palette.secondary.main,
                                        },
                                    }}
                                />
                                <Typography color={theme.palette.text.primary}>Salvar esse endereço para futuras compras?</Typography>
                            </Button>
                        </Box>
                        :
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Button onClick={() => {
                                const x = { ...props.form }
                                x.salvarEndereco = !x.salvarEndereco
                                props.updateForm(x)
                            }}>
                                <Checkbox
                                    checked={true}
                                    disabled
                                    onChange={() => {
                                        const x = { ...props.form }
                                        x.salvarEndereco = !x.salvarEndereco
                                        props.updateForm(x)
                                    }}
                                    sx={{
                                        color: theme.palette.background.paper,
                                        '&.Mui-checked': {
                                            color: theme.palette.background.paper,
                                        },
                                    }}
                                />
                                <Typography color={theme.palette.text.primary}>Esse endereço está salvo. Mude as informações à cima para atualizá-lo</Typography>
                            </Button>
                        </Box>
                    }
                    <Box sx={{ display: 'flex', alignSelf: 'center', gap: 1 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mb: 2, maxWidth: 300, color: '#fff', fontWeight: 'bold', backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                            onClick={() => {
                                const x = { ...props.open }
                                x.entrega.open = false
                                x.endereco.open = false
                                x.registro.open = false
                                x.produto.open = true
                                props.changeOpen(2)
                            }}
                        ><Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Voltar</Typography></Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mb: 2, maxWidth: 300, fontWeight: 'bold', backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                            onClick={() => {
                                if ([props.form.cep, props.form.rua, props.form.numero, props.form.cidade, props.form.estado, props.form.bairro].includes('')) {
                                    const y = { ...snackBar }
                                    y.open = true
                                    y.id = 1
                                    y.message = 'Preencha todos os campos para continuar'
                                    props.setSnackBar(y)
                                    return
                                }
                                setModalAtive(true)
                                getEntrega()
                            }}
                        ><Typography sx={{ color: '#fff', fontWeight: 'bold' }}>Próximo</Typography></Button>
                    </Box>
                </Box>
            </Box >
            <ModalLoading ative={modalAtive} closeModal={closeModal} />
            <SnackBarAlert ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
            <SnackBarSucess ative={snackBar.open} id={snackBar.id} message={snackBar.message} handleClose={closeSnack} />
        </Box >
    )
}