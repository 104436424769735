import React, { useState } from "react"
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import UseWindowDimensions from "./useWindowDimensions";
import { Box, Typography, Divider, TextField, Modal, Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import moment from "moment";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SnackBarAlert, SnackBarSucess } from "./snackbar"
import { Payment } from '@mercadopago/sdk-react';
import CloseIcon from '@mui/icons-material/Close';
export default props => {
    const theme = useTheme()
    const height = UseWindowDimensions().height
    const width = UseWindowDimensions().width
    if (props.active) {
        return (
            <Modal
                open={props.active}
                onClose={width < 700 ? null : props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableScrollLock={true}
                sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Box className="boxPayment" sx={{
                    overflow: 'scroll', maxHeight: height, width: '50%', [theme.breakpoints.down('md')]: {
                        width: '90%',
                    },
                }}>
                    <Box className="boxPayment" sx={{ position: 'relative', top: 55, display: 'flex', justifyContent: 'flex-end', zIndex: 1 }}>
                        <Button
                            onClick={() => props.onClose()}
                            sx={{ mb: 2, maxWidth: 300, fontWeight: 'bold', backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                        >
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Payment
                        initialization={props.initialization}
                        customization={{ paymentMethods: props.customization }}
                        onSubmit={props.onSubmit}
                        onReady={props.onReady}
                        onError={props.onError}
                    />
                </Box>
            </Modal>
        )
    }
    else {
        return null
    }
}