import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, List } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useLocation } from "react-router-dom";
import { decrypt } from "../../functions";
import moment from 'moment'
import { Pixel } from "../../components/pixel";


export default props => {
    const theme = useTheme();
    const [modalAtive, setModalAtive] = useState(false)
    const [data, setData] = useState({})
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    function closeModal() {
        setModalAtive(false)
    }
    Pixel()
    useEffect(() => {
        var x = query.getAll('t')
        if (![undefined, null, ''].includes(x[0])) {
            var x = x[0].split(' ').join('+')
            var x = decrypt(x)
            setData(x)
        }
    }, [])
    return (
        <Box sx={{ height: data?.endereco ? 'auto' : '100%', backgroundColor: theme.palette.background.paper, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box width={'40%'} bgcolor={theme.palette.background.default} marginTop={5} sx={{
                display: 'flex', justifyContent: 'center',
                [theme.breakpoints.down('lg')]: {
                    width: '50%'
                },
                [theme.breakpoints.down('md')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('sm')]: {
                    width: '95%'
                },
            }}>
                <Box sx={{ width: '70%', display: 'flex', flexDirection: 'column', gap: 3, marginTop: 5, marginBottom: 5 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <Box sx={{ width: 100, height: 100, borderRadius: 50, backgroundColor: theme.palette.secondary.main, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src="../assets/images/agradecimento/check.png" style={{ objectFit: 'contain' }} />
                        </Box>
                        <Typography variant="h3" fontWeight={'bold'} textAlign={'center'}>Obrigado pela compra!</Typography>
                        <Typography sx={{ textAlign: 'center' }}>A confirmação da compra com detalhes do seu pedido e o link para acompanhar seu pedido serão enviados para seu endereço de e-mail.</Typography>
                    </Box>
                    {data?.endereco ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, }}>
                            <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 2 }}>
                                <Typography fontWeight={'bold'}>{'ID DO PEDIDO: ' + data.orderId}</Typography>
                            </Box>
                            <Box sx={{ alignSelf: 'center' }}>
                                <Typography fontWeight={'bold'}>{'Data do pedido: ' + moment(data.dateCreated).format('DD/MM/YYYY HH:mm')}</Typography>
                            </Box>
                            <Box>
                                <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <Box sx={{ backgroundColor: theme.palette.background.default, padding: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <HomeIcon sx={{ color: theme.palette.secondary.main }} />
                                    </Box>
                                    <Typography fontWeight={'bold'}>ENDEREÇO DE ENTREGA</Typography>
                                </Box>
                                <Box sx={{ padding: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography fontWeight={'bold'}>Rua:</Typography>
                                        <Typography fontWeight={'bold'}>{data?.endereco?.rua}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography fontWeight={'bold'}>Número:</Typography>
                                        <Typography fontWeight={'bold'}>{data?.endereco?.numero}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography fontWeight={'bold'}>Bairro</Typography>
                                        <Typography fontWeight={'bold'}>{data?.endereco?.bairro}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography fontWeight={'bold'}>Cidade:</Typography>
                                        <Typography fontWeight={'bold'}>{data?.endereco?.cidade}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography fontWeight={'bold'}>Estado:</Typography>
                                        <Typography fontWeight={'bold'}>{data?.endereco?.estado}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography fontWeight={'bold'}>CEP:</Typography>
                                        <Typography fontWeight={'bold'}>{data?.endereco?.cep}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography fontWeight={'bold'}>Complemento:</Typography>
                                        <Typography fontWeight={'bold'}>{data?.endereco?.complemento}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <Box sx={{ backgroundColor: theme.palette.background.default, padding: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <LocalShippingIcon sx={{ color: theme.palette.secondary.main }} />
                                    </Box>
                                    <Typography fontWeight={'bold'}>FRETE</Typography>
                                </Box>
                                <Box sx={{ padding: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography fontWeight={'bold'}>Tipo:</Typography>
                                        <Typography fontWeight={'bold'}>{data?.frete?.name}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography fontWeight={'bold'}>Preço:</Typography>
                                        <Typography fontWeight={'bold'}>{'R$' + data?.frete?.preco}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography fontWeight={'bold'}>Prazo:</Typography>
                                        <Typography fontWeight={'bold'}>{'Dia da postagem + ' + data?.frete?.prazo?.value + ' dias'}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ backgroundColor: theme.palette.background.paper, padding: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <Box sx={{ backgroundColor: theme.palette.background.default, padding: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <ShoppingCartIcon sx={{ color: theme.palette.secondary.main }} />
                                    </Box>
                                    <Typography fontWeight={'bold'}>PRODUTO</Typography>
                                </Box>
                                <Box sx={{
                                    padding: 2, display: 'flex', gap: 2, alignItems: 'center',
                                    [theme.breakpoints.down('sm')]: {
                                        gap: 1,
                                        flexDirection: 'column'
                                    },
                                }}>
                                    <img src={data?.produto?.image} style={{ objectFit: 'contain', maxWidth: 150 }} />
                                    <Box>
                                        <Typography fontWeight={'bold'} fontSize={20} textAlign={'center'}>{'VitLov ' + data.produto.quantidade + ' Unidade'}</Typography>
                                        <Typography fontWeight={'bold'} fontSize={20} textAlign={'center'}>{'R$' + data.produto.preco}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        : null}
                    <Box>
                        <Button sx={{ backgroundColor: theme.palette.secondary.main, width: '100%', color: '#fff', borderRadius: 0, padding: 2 }}>
                            <Typography fontWeight={'bold'}>IR PARA MINHA CONTA</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}