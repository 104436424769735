import React, { useEffect, useState, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, List, TextField } from '@mui/material';
import mainApi from "../../apis/mainApi";
import { User } from '../../contexts/user'
import { TestaCPF, TestaEMAIL, TestaCELULAR } from "../../components/validaCampo";
import { cpfMask, celularMask, cepMask } from "../../components/masks";
import ContactPageIcon from '@mui/icons-material/ContactPage';

export default props => {
    const theme = useTheme();
    const user = useContext(User)
    const [disabled, setDisabled] = useState(true)
    const [newUser, setNewUser] = useState(user.data)
    const [error, setError] = useState({
        nome: false,
        sobrenome: false,
        email: false,
        cpf: false,
        celular: false,
        rua: false,
        numero: false,
        bairro: false,
        cidade: false,
        estado: false,
        cep: false
    })
    useEffect(() => {
        setNewUser(user.data)
    }, [user.data])
    if (newUser.hasOwnProperty('nome')) {
        return (
            <Box sx={{
                height: 'auto', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 5,
                [theme.breakpoints.down('md')]: {
                    padding: 1
                },
            }}>
                <Box sx={{ marginBottom: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', padding: 2, backgroundColor: theme.palette.background.paper }}>
                    <ContactPageIcon sx={{ fontSize: 70, color: theme.palette.primary.main, }} />
                    <Typography fontWeight={'bold'} sx={{ fontSize: 20 }}>Meus dados</Typography>
                </Box>
                <Box width={'100%'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3, backgroundColor: theme.palette.background.paper, padding: 5 }}>
                    <Box sx={{
                        display: 'flex', gap: 2,
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column'
                        },
                    }}>
                        <TextField
                            fullWidth
                            margin="none"
                            disabled={disabled}
                            required
                            value={newUser.nome}
                            id="nome"
                            name="nome"
                            label="Primeiro Nome"
                            onChange={(value) => {
                                const x = { ...newUser }
                                x.nome = value.target.value
                                setNewUser(x)
                            }}
                            error={error.nome}
                            onBlur={(value) => {
                                const x = { ...error }
                                x.nome = value.target.value === '' ? true : false
                                setError(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.primary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            margin="none"
                            disabled={disabled}
                            required
                            value={newUser.sobrenome}
                            id="sobrenome"
                            name="sobrenome"
                            label="Sobrenome"
                            error={error.sobrenome}
                            onChange={(value) => {
                                const x = { ...newUser }
                                x.sobrenome = value.target.value
                                setNewUser(x)
                            }}
                            onBlur={(value) => {
                                const x = { ...error }
                                x.sobrenome = value.target.value === '' ? true : false
                                setError(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.primary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        margin="none"
                        disabled={disabled}
                        required
                        value={newUser.email}
                        id="email"
                        name="email"
                        label="E-mail"
                        error={error.email}
                        onChange={(value) => {
                            const x = { ...newUser }
                            x.email = value.target.value
                            setNewUser(x)
                        }}
                        onBlur={(value) => {
                            const x = { ...error }
                            const TestEmail = TestaEMAIL(value.target.value)
                            x.email = TestEmail === true ? false : true
                            setError(x)
                        }}
                        InputLabelProps={{
                            style: {
                                color: theme.palette.text.primary,
                                borderColor: '#eee'
                            }
                        }}
                        autoComplete=""
                        inputProps={{
                            style: {
                                backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                borderRadius: 5
                            }
                        }}
                    />
                    <Box sx={{
                        display: 'flex', gap: 2,
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column'
                        },
                    }}>
                        <TextField
                            fullWidth
                            margin="none"
                            disabled={disabled}
                            required
                            value={newUser.cpf}
                            id="cpf"
                            name="cpf"
                            label="CPF"
                            error={error.cpf}
                            onChange={(value) => {
                                const x = { ...newUser }
                                x.cpf = cpfMask(value.target.value)
                                setNewUser(x)
                            }}
                            onBlur={(value) => {
                                const x = { ...error }
                                const testaCPF = TestaCPF(value.target.value)
                                x.cpf = testaCPF === true ? false : true
                                setError(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.primary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            margin="none"
                            disabled={disabled}
                            required
                            value={newUser.celular}
                            id="celular"
                            name="celular"
                            label="Celular"
                            error={error.celular}
                            onChange={(value) => {
                                const x = { ...newUser }
                                x.celular = celularMask(value.target.value)
                                setNewUser(x)
                            }}
                            onBlur={(value) => {
                                const x = { ...error }
                                const testeCelular = TestaCELULAR(value.target.value)
                                x.celular = testeCelular === true ? false : true
                                setError(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.primary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        margin="none"
                        disabled={disabled}
                        required
                        value={newUser.cep}
                        id="cep"
                        name="cep"
                        label="CEP"
                        error={error.cep}
                        onChange={(value) => {
                            const x = { ...newUser }
                            x.cep = cepMask(value.target.value)
                            setNewUser(x)
                        }}
                        onBlur={(value) => {
                            if (value.target.value === '') {
                                return
                            }
                            if (value.target.value.length < 9) {
                                const newErrors = { ...error }
                                newErrors.cep = true
                                setError(newErrors)
                                return
                            }
                            props.updateModal(true)
                            function callbackReturn(callback) {
                                props.updateModal(false)
                                if (callback.status !== 200) {
                                    const newErrors = { ...error }
                                    newErrors.cep = true
                                    setError(newErrors)
                                    return
                                }
                                const newErrors = { ...error }
                                newErrors.cep = false
                                setError(newErrors)
                                const apiReturn = callback?.apiReturn?.apiReturn
                                const x = { ...newUser }
                                x.cidade = apiReturn?.localidade ? apiReturn.localidade : x.cidade
                                x.rua = apiReturn?.logradouro ? apiReturn.logradouro : x.rua
                                x.bairro = apiReturn?.bairro ? apiReturn.bairro : x.bairro
                                x.estado = apiReturn?.uf ? apiReturn.uf : x.estado
                                setNewUser(x)
                            }
                            mainApi('correios/cep', 'POST', { cep: value.target.value }, callbackReturn)
                        }}
                        InputLabelProps={{
                            style: {
                                color: theme.palette.text.primary,
                                borderColor: '#eee'
                            }
                        }}
                        autoComplete=""
                        inputProps={{
                            style: {
                                backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                borderRadius: 5
                            }
                        }}
                    />
                    <Box sx={{
                        display: 'flex', gap: 2,
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column'
                        },
                    }}>
                        <TextField
                            fullWidth
                            margin="none"
                            disabled={disabled}
                            required
                            value={newUser.rua}
                            id="rua"
                            name="rua"
                            label="Rua"
                            error={error.rua}
                            onChange={(value) => {
                                const x = { ...newUser }
                                x.rua = value.target.value
                                setNewUser(x)
                            }}
                            onBlur={(value) => {
                                const x = { ...error }
                                x.rua = value.target.value === '' ? true : false
                                setError(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.primary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            margin="none"
                            disabled={disabled}
                            required
                            value={newUser.numero}
                            id="numero"
                            name="numero"
                            label="Número"
                            error={error.numero}
                            onChange={(value) => {
                                const x = { ...newUser }
                                x.numero = value.target.value
                                setNewUser(x)
                            }}
                            onBlur={(value) => {
                                const x = { ...error }
                                x.numero = value.target.value === '' ? true : false
                                setError(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.primary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        margin="none"
                        disabled={disabled}
                        required
                        value={newUser.bairro}
                        id="bairro"
                        name="bairro"
                        label="Bairro"
                        error={error.bairro}
                        onChange={(value) => {
                            const x = { ...newUser }
                            x.bairro = value.target.value
                            setNewUser(x)
                        }}
                        onBlur={(value) => {
                            const x = { ...error }
                            x.bairro = value.target.value === '' ? true : false
                            setError(x)
                        }}
                        InputLabelProps={{
                            style: {
                                color: theme.palette.text.primary,
                                borderColor: '#eee'
                            }
                        }}
                        autoComplete=""
                        inputProps={{
                            style: {
                                backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                borderRadius: 5
                            }
                        }}
                    />
                    <Box sx={{
                        display: 'flex', gap: 2,
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column'
                        },
                    }}>
                        <TextField
                            fullWidth
                            margin="none"
                            disabled={disabled}
                            required
                            value={newUser.cidade}
                            id="cidade"
                            name="cidade"
                            label="Cidade"
                            error={error.cidade}
                            onChange={(value) => {
                                const x = { ...newUser }
                                x.cidade = value.target.value
                                setNewUser(x)
                            }}
                            onBlur={(value) => {
                                const x = { ...error }
                                x.cidade = value.target.value === '' ? true : false
                                setError(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.primary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            margin="none"
                            disabled={disabled}
                            required
                            value={newUser.estado}
                            id="estado"
                            name="estado"
                            label="Estado"
                            error={error.estado}
                            onChange={(value) => {
                                const x = { ...newUser }
                                x.estado = value.target.value
                                setNewUser(x)
                            }}
                            onBlur={(value) => {
                                const x = { ...error }
                                x.estado = value.target.value === '' ? true : false
                                setError(x)
                            }}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.text.primary,
                                    borderColor: '#eee'
                                }
                            }}
                            autoComplete=""
                            inputProps={{
                                style: {
                                    backgroundColor: disabled ? theme.palette.background.default : theme.palette.background.paper,
                                    borderRadius: 5
                                }
                            }}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex', gap: 2,
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column'
                        },
                    }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                setDisabled(!disabled)
                            }}
                            sx={{ mb: 2, backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.secondary.rgba }, borderRadius: 0, padding: 1 }}
                        >Editar</Button>
                        <Button
                            type="submit"
                            fullWidth
                            disabled={disabled}
                            variant="contained"
                            onClick={async () => {
                                user.changeData(newUser)
                                function FCallback(callback) {
                                }
                                await mainApi('clientes', 'PUT', newUser, FCallback)
                            }}
                            sx={{ mb: 2, backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                        >Salvar</Button>
                    </Box>
                </Box>
            </Box >
        )
    }
    else return null
}