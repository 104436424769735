import React from "react";
import { Box, Typography, Modal, CircularProgress, useTheme, Button } from "@mui/material"
import moment from 'moment'


export function ModalDepoimento(props) {
    const theme = useTheme()
    const couponStyle = {
        border: `2px dashed ${theme.palette.secondary.main}`,
        padding: "20px",
        width: "250px",
        textAlign: "center",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
    };
    const titleStyle = {
        fontSize: "1.5rem",
        fontWeight: "bold",
        margin: "10px 0",
    };

    const detailStyle = {
        fontSize: "1rem",
        margin: "5px 0",
    };
    return (
        <Modal
            open={props.ative}
            onClose={() => props.closeModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: 300,
                width: '50%',
                transform: 'translate(-50%, -50%)',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                outline: 'none',
                [theme.breakpoints.down('lg')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('md')]: {
                    width: '80%'
                },
                [theme.breakpoints.down('sm')]: {
                    width: '98%',
                },
            }}>
                <Box sx={{ width: '100%' }}>
                    {props.data}
                </Box>
            </Box>
        </Modal>
    )
}

