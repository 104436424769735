import mainApi from "./mainApi"

export const insertClientes = async (cpf, email, senha, celular, primeiro_nome, sobrenome, Fcallback) => {
    const body = {
        cpf: cpf,
        email: email,
        senha: senha,
        celular: celular,
        primeiro_nome: primeiro_nome,
        sobrenome: sobrenome,
    }
    console.log(body)
    await mainApi('clientes/register', 'POST', body, Fcallback)
}

export const loginClientes = async (email, senha, Fcallback) => {
    const body = {
        email: email,
        senha: senha
    }
    await mainApi('clientes/login', 'POST', body, Fcallback)
}