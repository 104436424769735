import { createContext, useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { decrypt, encrypt } from '../functions';
import mainApi from '../apis/mainApi';


export const User = createContext()

export default props => {
    const dataInit = {
        token: ''
    }
    const [data, setData] = useState(dataInit)
    const cookies = new Cookies()
    async function getCookies() {
        var customer = cookies.get('customer')
        if (customer) {
            var decryptedData = decrypt(customer)
            const body = {
                token: decryptedData.token
            }
            function FCalback(callback) {
                if (callback.status === 200) {
                    changeData(callback.apiReturn.apiReturn)
                    return
                }
                setData(decryptedData)
            }
            await mainApi('clientes/login_token', 'POST', body, FCalback)
        }
    }
    const changeData = (value) => {
        var ciphertext = encrypt(value)
        cookies.set('customer', ciphertext, { path: '/', maxAge: 3000 });
        setData(value)
    }
    useEffect(() => {
        getCookies()
    }, [])

    const deleteData = () => {
        cookies.remove('customer')
        cookies.remove('cart')
        setData(dataInit)
    }

    return (
        <User.Provider value={{ data, changeData, deleteData }}>
            {props.children}
        </User.Provider>
    )
}
