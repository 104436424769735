import React, { useEffect, useState, useMemo, useContext } from "react";
import { render } from 'react-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Modal, Typography, Button } from '@mui/material';
import { StatusScreen } from '@mercadopago/sdk-react';
import UseWindowDimensions from "./useWindowDimensions";
import { encrypt } from "../functions";
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
import { Product } from "../contexts/product";

export default props => {
    const product = useContext(Product)
    const height = UseWindowDimensions().height
    const theme = useTheme()
    const [timer, setTimer] = useState(30)
    const [controler, setControler] = useState(0)
    useEffect(() => {
        if (props.active && props?.paymentMethod === 'pix') {
            if (timer < 1) {
                setTimer(30)
                setControler(controler + 1)
            }
            const interval = setInterval(() => {
                setTimer(timer - 1)
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [timer, props.active])
    return (
        <Modal
            open={props.active}
            onClose={() => {
                if (props?.canDesactive === true) {
                    props.desactive()
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock={true}
            sx={{
                height: '100% !important',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            componentsProps={{
                style: {
                    height: '100% !important'
                }
            }}
        >
            <Box className="boxPayment" sx={{
                overflow: 'scroll', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxHeight: height, [theme.breakpoints.down('md')]: {
                    padding: 1
                },
            }}>
                <Box className="boxPayment" sx={{
                    position: 'relative', top: 130, display: 'flex', justifyContent: 'flex-end', zIndex: 1, width: '100%',
                    [theme.breakpoints.up('sm')]: {
                        display: 'none'
                    },
                }}>
                    <Button
                        onClick={() => props.desactive()}
                        sx={{ maxWidth: 300, fontWeight: 'bold', backgroundColor: 'rgba(0,0,0,0.5)', color: theme.palette.text.primary, '&:hover': { backgroundColor: theme.palette.primary.rgba }, borderRadius: 0, padding: 1 }}
                    >
                        <CloseIcon />
                    </Button>
                </Box>
                {useMemo(() => {
                    const agradecimentoInfo = {
                        orderId: props.serverId,
                        dateCreated: props.dateCreated,
                        endereco: {
                            rua: props.form.rua,
                            numero: props.form.numero,
                            bairro: props.form.bairro,
                            cidade: props.form.cidade,
                            estado: props.form.estado,
                            cep: props.form.cep,
                            complemento: props.form.complemento
                        },
                        frete: {
                            nome: props.form.entrega.name,
                            preco: props.form.entrega.preco,
                            prazo: props.form.entrega.prazo
                        },
                        produto: props?.form?.product ? props?.form?.product : product?.data?.activeItem
                    }
                    const x = encrypt(agradecimentoInfo)
                    return (
                        <StatusScreen
                            initialization={{ paymentId: props.id }}
                            onReady={props.onReady}
                            onError={props.onError}
                            customization={{
                                backUrls: props?.canDesactive ? null : {
                                    return: window.location.origin + '/carrinho/agradecimento?t=' + x,
                                    error: window.location.origin + '/carrinho'
                                },
                                visual: {
                                    texts: {
                                        ctaReturnLabel: 'Continuar'
                                    }
                                }
                            }}
                        />
                    )
                }, [props.id, controler])}
                {props?.paymentMethod === 'pix' || props?.paymentMethod === 'PIX' ?
                    <Box className="scaleTransform" sx={{
                        backgroundColor: '#fff',
                        width: '100%',
                        padding: 1,
                        border: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}>
                        <Typography variant="h5" color={'#1A1A1A'} fontWeight={'bold'}>{'O status da transação será recarregado em ' + timer + ' segundos'}</Typography>
                    </Box>
                    : null}
            </Box>
        </Modal>
    )
}