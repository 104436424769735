import { useContext, useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import { Routes, Route } from 'react-router-dom'
import Home from "./views/home/home"
import Error from "./views/404/error"
import Carrinho from './views/cart/cart'
import Construcao from "./views/construcao/construcao"
import Agradecimento from "./views/cart/agradecimento"
import MinhaConta from "./views/minha-conta/minhaConta"
import MinhaContaOFF from "./views/minha-conta/minhaContaOFF"
import { Product } from "./contexts/product"
import { Header } from "./components/header"
import { User } from "./contexts/user"
import { Footer } from "./components/footer"

export default props => {
    const [loading, setLoading] = useState(true)
    const product = useContext(Product)
    const user = useContext(User)
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])
    const manutencao = false
    return (
        <Box display="flex" width="100%" height="100%">
            <div className="app">
                <main className="content">
                    <Header manutencao={manutencao} />
                    <Box sx={{ height: 50 }} />
                    {manutencao === true ?
                        <Construcao />
                        :
                        <>
                            {product?.data === false ?
                                <Construcao />
                                :
                                <Routes>
                                    <Route path="/" element={<Home setModalPolitica={props.setModalPolitica} />} />
                                    <Route path="*" element={<Home />} />
                                    <Route path="/carrinho" element={<Carrinho setModalPolitica={props.setModalPolitica} />} />
                                    <Route path="/carrinho/agradecimento" element={<Agradecimento />} />
                                    <Route path="/minha-conta" element={[null, undefined, ''].includes(user?.data?.token) ? <MinhaContaOFF setModalPolitica={props.setModalPolitica} /> : <MinhaConta />} />
                                </Routes>
                            }
                            <Footer setModalPolitica={props.setModalPolitica} />
                        </>
                    }
                </main>
            </div >
        </Box>
    )
}