var local = false

var adress = local == false ? 'https://fbfadm.com.br/server' : 'http://localhost:21032'

const mainApi = async (type, method, body, functionCallBack, userToken) => {
    const headers = {
        'User-Agent': 'Android v1',
        'App-Token': '1:a19f75bd469df806906d0c13d4e76ca4',
        'Cache-Control': 'no-cache, no-store',
        'Pragma': 'no-cache',
        'Content-Type': 'application/json',
        'user-token': userToken ? userToken : '',
        'if-none-match': '',
        'Application': 1
    }
    const requestReturn = {
        status: false,
        apiReturn: []
    }
    const timeOutApi = new Promise((resolve, reject) => {
        const x = {
            status: 3200,
            message: 'O servidor está com problemas. Tente novamente mais tarde.'
        }
        setTimeout(resolve, 10000, x)
    })
    const send = body != null ? {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    } : {
        method: method,
        headers: headers,
    }
    const apiRequest = async () => {
        await fetch(adress + '/' + type, send).then((response) => {
            requestReturn.status = response.status
            return response.json()
        }).then((json) => {
            requestReturn.apiReturn = json
        }).catch((error) => console.log(JSON.stringify(error)))
        return requestReturn
    }
    Promise.race([apiRequest(), timeOutApi]).then((value) => {
        functionCallBack(value)
    })
}

export default mainApi