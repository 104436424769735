import React from "react";
import { useTheme } from '@mui/material/styles';
import { Box, IconButton, Menu, MenuItem, Divider, Badge, Button, Typography } from '@mui/material';
import { User } from "../contexts/user";
import { Product } from "../contexts/product";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Fade from '@mui/material/Fade';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { Link } from "react-router-dom";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
export const Header = (props) => {
    const theme = useTheme()
    const user = React.useContext(User)
    const product = React.useContext(Product)
    const [menuOpen, setMenuOpen] = React.useState({
        open: false,
        anchor: 0
    })
    return (
        <Box sx={{ position: 'fixed', zIndex: 1000, top: 0, left: 0, width: '100%', backgroundColor: theme.palette.background.default, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ width: '70%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                    disableTouchRipple disableFocusRipple
                    component={Link}
                    to='/'
                    sx={{
                        '&:focus': {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    <img src="../assets/images/logos/LogoHeader.png" height={60} style={{ position: 'relative', top: 8, objectFit: 'cover' }} />
                </Button>
                {props.manutencao ?
                    null
                    :

                    <Box sx={{ display: 'flex', gap: 3 }}>
                        <IconButton aria-label="cart" sx={{ position: 'relative', top: 3 }} component={Link}
                            to='carrinho'>
                            <Badge badgeContent={product?.data?.activeItem?.quantidade ? product?.data?.activeItem?.quantidade : '0'} color="secondary">
                                <ShoppingCartIcon sx={{ fontSize: 25, color: theme.palette.text.primary }} />
                            </Badge>
                        </IconButton>
                        <IconButton
                            onClick={(value) => {
                                const x = { ...menuOpen }
                                x.open = !x.open
                                x.anchor = value.currentTarget
                                setMenuOpen(x)
                            }}
                        >
                            <AccountCircleIcon sx={{ fontSize: 35, color: theme.palette.text.primary }} />
                        </IconButton>
                        {['', undefined, null].includes(user?.data?.token) ?
                            <Menu
                                open={menuOpen.open}
                                anchorEl={menuOpen.anchor}
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                onClose={() => {
                                    const x = { ...menuOpen }
                                    x.open = !x.open
                                    setMenuOpen(x)
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                TransitionComponent={Fade}
                            >
                                <MenuItem
                                    component={Link}
                                    to='minha-conta'
                                    onClick={() => {
                                        const x = { ...menuOpen }
                                        x.open = false
                                        setMenuOpen(x)
                                    }}
                                    sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                                >
                                    <LoginIcon />
                                    Entrar
                                </MenuItem>
                            </Menu>
                            :
                            <Menu
                                open={menuOpen.open}
                                anchorEl={menuOpen.anchor}
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                onClose={() => {
                                    const x = { ...menuOpen }
                                    x.open = !x.open
                                    setMenuOpen(x)
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                TransitionComponent={Fade}
                            >
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.palette.background.default, padding: 1 }}>
                                    <AccountBoxIcon sx={{ fontSize: 30 }} />
                                    <Typography>{user.data.nome}</Typography>
                                </Box>
                                <Divider />
                                <MenuItem
                                    component={Link}
                                    to='minha-conta?t=0'
                                    sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                                    onClick={() => {
                                        const x = { ...menuOpen }
                                        x.open = false
                                        setMenuOpen(x)
                                    }}
                                >
                                    <ContactPageIcon />
                                    Meus Dados
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                    component={Link}
                                    to='minha-conta?t=1'
                                    sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                                    onClick={() => {
                                        const x = { ...menuOpen }
                                        x.open = false
                                        setMenuOpen(x)
                                    }}
                                >
                                    <ShoppingCartIcon />
                                    Pedidos
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                    sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                                    onClick={() => {
                                        user.deleteData()
                                        product.deleteActiveItem()
                                    }}
                                >
                                    <LogoutIcon />
                                    Sair
                                </MenuItem>
                            </Menu>
                        }
                    </Box>
                }
            </Box>
        </Box>
    )
}