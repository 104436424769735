import React from "react";
import { Box, Typography, Modal, CircularProgress, useTheme } from "@mui/material"

export function ModalInfo(props) {
    const theme = useTheme()
    return (
        <Modal
            open={props.ative}
            onClose={() => props.closeModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: 300,
                width: '30%',
                transform: 'translate(-50%, -50%)',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                outline: 'none',
                backgroundColor: theme.palette.background.paper,
                [theme.breakpoints.down('md')]: {
                    width: '70%'
                },
                [theme.breakpoints.down('sm')]: {
                    width: '90%'
                },

            }}>
                {props.body}
            </Box>
        </Modal>
    )
}

