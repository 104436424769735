import React from "react";
import { Box, Typography, Modal, CircularProgress, useTheme } from "@mui/material"

export function ModalPolitica(props) {
    const theme = useTheme()
    return (
        <Modal
            open={props.visible}
            onClose={() => props.changeModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                outline: 'none',
                backgroundColor: theme.palette.background.default,
                width: 500,
                height: 500,
                [theme.breakpoints.down('md')]: {
                    width: '90%',
                },

            }}>
                <Box sx={{ width: '95%', display: 'flex', flexDirection: 'column', gap: 3, padding: 2, backgroundColor: theme.palette.background.paper, height: '95%', overflow: 'scroll', '&::-webkit-scrollbar': { display: 'none' } }}>
                    <Typography variant="h2">Política de privacidade</Typography>
                    <Box>
                        <Typography>
                            A sua privacidade é importante para nós. Esta política de privacidade descreve como coletamos, usamos, divulgamos e protegemos as informações pessoais que você nos fornece ao usar o site VITLOV. Ao acessar ou usar nosso site, você concorda com os termos e condições desta política.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">Informações Coletadas:</Typography>
                        <Typography>
                            Coletamos informações pessoais que você nos fornece voluntariamente, como nome, endereço de e-mail, informações de pagamento e qualquer outra informação que você opte por compartilhar ao entrar em contato conosco ou realizar uma compra.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">Uso de Informações:</Typography>
                        <Typography>
                            Usamos suas informações pessoais para processar pedidos, fornecer suporte ao cliente, enviar atualizações sobre nossos produtos e responder a perguntas ou preocupações que você possa ter. Não compartilharemos suas informações pessoais com terceiros, a menos que seja necessário para cumprir com uma solicitação ou ordem legal.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">Cookies:</Typography>
                        <Typography>
                            Usamos cookies e tecnologias semelhantes para melhorar a experiência do usuário em nosso site. Essas tecnologias coletam informações, como o endereço IP, tipo de navegador, páginas visitadas e duração da visita. Você pode optar por desativar cookies nas configurações do seu navegador, mas isso pode afetar a funcionalidade do site.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">Segurança:</Typography>
                        <Typography>
                            Tomamos medidas razoáveis para proteger suas informações pessoais contra acesso não autorizado, divulgação ou destruição. No entanto, não podemos garantir a segurança absoluta na internet.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">Links para Terceiros:</Typography>
                        <Typography>
                            Nosso site pode conter links para sites de terceiros. Esta política de privacidade não se aplica a sites de terceiros e não somos responsáveis pelas práticas de privacidade ou conteúdo nesses sites.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">Alterações na Política:</Typography>
                        <Typography>
                            Reservamo-nos o direito de fazer alterações nesta política de privacidade a qualquer momento. Quaisquer alterações entrarão em vigor imediatamente após a publicação no site.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">Contato:</Typography>
                        <Typography>
                            Se você tiver alguma dúvida sobre nossa política de privacidade ou desejar acessar, corrigir ou excluir suas informações pessoais, entre em contato conosco através do nosso e-mail:

                            suporte@franchisingbrasilfarma.com.br
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Modal >
    )
}

