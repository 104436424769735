import { CssBaseline, ThemeProvider, } from "@mui/material";
import { useMode, ColorModeContext } from "./theme/theme";
import React, { useState } from "react"
import Route from "./route"
import './index.css'
import Product from "./contexts/product";
import User from "./contexts/user";
import { initMercadoPago } from "@mercadopago/sdk-react";
import { ModalPolitica } from "./components/modalPolitica";
import { PixelInit } from "./components/pixel";

export default props => {
    const [theme, colorMode] = useMode()
    const [visible, setVisible] = useState(false)
    initMercadoPago("APP_USR-67167844-f036-400b-90eb-2dcd1a32d2a1", { locale: 'pt-BR' })

    function changeModal(value) {
        setVisible(value)
    }

    React.useEffect(() => {
        PixelInit()
    }, [])

    return (
        <User>
            <Product>
                <ColorModeContext.Provider value={colorMode}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Route setModalPolitica={changeModal}></Route>
                        <ModalPolitica visible={visible} changeModal={changeModal} />
                    </ThemeProvider>
                </ColorModeContext.Provider>
            </Product>
        </User>
    )
}