import React, { useState } from "react";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


export function SnackBarAlert(props) {
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} sx={{ color: '#fff' }} />;
    });
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={props.id === 1 ? props.ative : false} autoHideDuration={6000} onClose={props.handleClose}>
                <Alert onClose={props.handleClose} severity="error" sx={{ width: '100%' }}>
                    {props.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
export function SnackBarSucess(props) {
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={props.id === 2 ? props.ative : false} autoHideDuration={6000} onClose={props.handleClose}>
                <Alert onClose={props.handleClose} severity="success" sx={{ width: '100%' }}>
                    {props.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}