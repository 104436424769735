import React, { useState, useContext } from "react";
import { Box, Typography, Modal, CircularProgress, useTheme, TextField, ButtonGroup, Button } from "@mui/material"
import Registro from "../views/cart/registro";
import { User } from "../contexts/user";
import { loginClientes, insertClientes } from "../apis/clientes";
import { TestaEMAIL, TestaCELULAR, TestaCPF } from "./validaCampo";
import { cpfMask, celularMask } from "./masks";

export function ModalRegistro(props) {
    const theme = useTheme()
    const user = useContext(User)
    const [registerControll, setRegisterControll] = useState(0)
    const [userInfo, setUserInfo] = useState({
        'E-mail': '',
        'Senha': '',
        'CPF': '',
        'Nome': '',
        'Sobrenome': '',
        'Celular': ''
    })
    const [error, setError] = useState(null)
    async function login() {
        if (userInfo['E-mail'] === '') {
            setError('E-mail não pode ser vazio')
            return
        }
        if (TestaEMAIL(userInfo['E-mail']) === false) {
            setError('E-mail inválido prenchido')
            return
        }
        function Fcallback(callback) {
            if (callback?.status !== 200) {
                const message = callback?.apiReturn?.message ? callback?.apiReturn?.message : 'Não foi possível cadastrar o usuário'
                setError(message)
                /* props.setSnackBar(y) */
                return
            }
            setError(null)
            user.changeData(callback.apiReturn.apiReturn)
            if (props.getCompra) {
                props.getCompra(callback.apiReturn.apiReturn)
            }
            props.closeModal()
        }
        loginClientes(userInfo['E-mail'], userInfo['Senha'], Fcallback)
    }
    async function register() {
        if (userInfo['E-mail'] === '') {
            setError('E-mail não pode ser vazio')
            return
        }
        if (TestaEMAIL(userInfo['E-mail']) === false) {
            setError('E-mail inválido prenchido')
            return
        }
        if (TestaCPF(userInfo['CPF']) === false) {
            setError('CPF inválido preenchido')
            return
        }
        if (TestaCELULAR(userInfo['Celular']) === false) {
            setError('Celular inválido preenchido')
            return
        }
        function Fcallback(callback) {
            console.log(callback)
            if (callback?.status !== 200) {
                const message = callback?.apiReturn?.message ? callback?.apiReturn?.message : 'Não foi possível cadastrar o usuário'
                setError(message)
                return
            }
            setError(null)
            user.changeData(callback.apiReturn.apiReturn)
            if (props.getCompra) {
                props.getCompra(callback.apiReturn.apiReturn)
            }
            props.closeModal()
        }
        insertClientes(userInfo['CPF'], userInfo['E-mail'], userInfo['Senha'], userInfo['Celular'], userInfo['Nome'], userInfo['Sobrenome'], Fcallback)
    }
    return (
        <Modal
            open={props.ative}
            onClose={() => props.closeModal()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: "flex",
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                outline: 'none',
                backgroundColor: theme.palette.background.default,
                width: 500,
                height: 'auto',
                minHeight: 450,
                flexWrap: 'wrap',
                gap: 2,
                padding: 4,
                [theme.breakpoints.down('md')]: {
                    width: '90%',
                },

            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontSize: 25 }}>Estamos quase lá!</Typography>
                    <Typography sx={{ color: theme.palette.text.primary }}>Preencha suas informações para acompanhar o seu pedido e acessar sua conta VITLOV.</Typography>
                </Box>
                {!user?.data?.token ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                        <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ alignSelf: 'center', gap: 1 }}>
                            <Button onClick={() => setRegisterControll(1)} sx={{ padding: 1, borderRadius: 0, borderRightColor: theme.palette.secondary.main + ' !important', borderColor: theme.palette.secondary.main, backgroundColor: registerControll === 1 ? theme.palette.secondary.main : null, color: registerControll === 1 ? theme.palette.text.primary : theme.palette.secondary.main, '&:hover': { borderColor: theme.palette.secondary.main, color: theme.palette.text.primary, backgroundColor: theme.palette.secondary.rgba } }}>Entrar</Button>
                            <Button onClick={() => setRegisterControll(2)} sx={{ padding: 1, borderRadius: 0, backgroundColor: registerControll === 2 ? theme.palette.primary.main : null, color: registerControll === 2 ? theme.palette.text.primary : null, '&:hover': { color: theme.palette.text.primary } }}>Registrar</Button>
                        </ButtonGroup>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'flex-start', gap: 2 }}>
                            {Object.keys(userInfo).map((e, idx) => {
                                if (!['E-mail', 'Senha'].includes(e) && registerControll === 1) return
                                return (
                                    <TextField
                                    key={idx}
                                        fullWidth
                                        margin="none"
                                        required
                                        value={e === 'CPF' ? cpfMask(userInfo[e]) : e === 'Celular' ? celularMask(userInfo[e]) : userInfo[e]}
                                        id={e}
                                        name={e}
                                        type={e === "Senha" ? "password" : e}
                                        label={e}
                                        onChange={(value) => {
                                            setError(null)
                                            const x = { ...userInfo }
                                            x[e] = value.target.value
                                            setUserInfo(x)
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.text.secondary,
                                                borderColor: '#eee'
                                            }
                                        }}
                                        /* onBlur={(value) => {
                                            const x = { ...error }
                                            const TestEmail = TestaEMAIL(value.target.value)
                                            x.email = TestEmail === true ? false : true
                                            setError(x)
                                            if (TestEmail === false) {
                                                setTimeout(() => {
                                                    const y = { ...snackBar }
                                                    y.open = true
                                                    y.id = 1
                                                    y.message = 'E-mail inválido'
                                                    setSnackBar(y)
                                                }, 500)
                                            }
                                        }} */
                                        sx={{ width: '45%' }}
                                        autoComplete=""
                                        inputProps={{
                                            style: {
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: 5
                                            }
                                        }}
                                    />
                                )
                            })}
                            <Button onClick={() => registerControll === 1 ? login() : register()} sx={{ padding: 1, borderRadius: 1, backgroundColor: registerControll === 1 ? theme.palette.secondary.main : theme.palette.primary.main, color: theme.palette.text.primary, width: '93%', '&:hover': { color: theme.palette.text.primary } }}>{registerControll === 1 ? 'Entrar' : 'Salvar'}</Button>
                        </Box>
                        {!['', undefined, null].includes(error) ?
                            <Box sx={{ background: theme.palette.warning.main, width: '93%', padding: 1, borderRadius: 1 }}>
                                <Typography sx={{ color: theme.palette.text.primary }}>{error}</Typography>
                            </Box>
                            : null}
                    </Box>
                    : null
                }
            </Box>
        </Modal >
    )
}

